import React, { useEffect } from "react";
import Banner from "../../components/banner/Banner";
import "./EventsPage.css";
import { useNavigate } from "react-router-dom";
import "animate.css/animate.compat.css";
import {
  img_events_desktop,
  img_events_mobile,
  Aifest4
} from "../../constants/image_constants";
import { useDispatch, useSelector } from "react-redux";
import { getAllEventsList } from "../../redux/slices/event.slice";
import { ClipLoader } from "react-spinners";

const EventsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const eventsState = useSelector(({ event }) => event);
  const { events, loader, error } = eventsState;

  useEffect(() => {
    dispatch(getAllEventsList());
  }, []);

  return (
    <div className="eventsPage">
      <Banner
        title={"Events"}
        titleClasses={"events-banner"}
        img={{ desktop: img_events_desktop, mobile: img_events_mobile }}
      />
      {events?.length > 0 && loader == false ? (
        <div className="cards-container">
          {events.map(({ _id, title, startDate, thumbnail, about }) => (
            <div
              key={_id}
              className="cards"
              onClick={() => {
                window.scrollTo(0,0);
                navigate("/events/" + _id);
              }}
            >
              <img src={Aifest4} alt="thumbnail of AI Club" />
              <div
                className="cards-text"
              >
                <p>{new Date(startDate).toDateString()}</p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div
          style={{
            height: "40vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: "grey",
            backgroundColor: "whitesmoke",
          }}
        >
          {error && (
            <h2 style={{ textAlign: "center" }}>
              OOPS! COULD NOT FETCH DATA FROM SERVER. PLEASE RELOAD PAGE
            </h2>
          )}
          {loader && <ClipLoader size={100} loading={loader} color={"blue"} />}
          {!error && events?.length === 0 && (<h2>NO DATA</h2>)}
        </div>
      )}
    </div>
  );
};

export default EventsPage;
