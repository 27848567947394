import React, { useEffect, useState } from "react";
import blogServices from "../../../axios/services/blog.service";
import "./BlogsSection.css";
import "animate.css";
import BlogCard from "../../blogCard/BlogCard";

function BlogsSection() {


    const [blogs, setBlogs] = useState([]);
    const [errMsg, setErrMsg] = useState(null);
  
    const getBlogs = async () => {
      try {
        let response = await blogServices.getBlogList({size:3});
        if (response?.data?.data.length && response?.status === 200) {
          setBlogs(response.data.data);
          setErrMsg(null);
        }
      } catch (err) {
        setErrMsg("Something Went Wrong");
      }
    };
  
    useEffect(() => {
      getBlogs();
    }, []);

  const blogsList = () => {
    return blogs.map((blog)=>{
        return BlogCard(blog)
    });
  }

  return (
    <section className="blogs-sec sec6 p-5">
      <h2 className="">Our Blogs</h2>
      <div className="list-container">
        {blogsList()}
      </div>
    </section>
  );
}

export default BlogsSection;
