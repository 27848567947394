import React from "react";
import "animate.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "./herosection.css";
const HeroSection = () => {
  return (
    <section
      className={"sec sec1 hero-sec"}
    >
      {/* 25dsdfkjdckjxhcdskc */}
      {/* <div
        // className={"imageBg border"}
        // style={{
        //   backgroundImage: `url(${image})`,
        //   backgroundSize: "cover",
        //   backgroundPosition: "center",
        // }}
      >
        <div >
          <div className={"titlePara text-dark"}>
            <h1>Advancing AI In Pakistan</h1>
            <br />
            <h2>
              We Are Leading The AI Revolution In Pakistan, Educating The The
              Next Generation Of Pakistani AI Researchers And Innovators
            </h2>
            <br />
            <button type="button" className="joinUsBtn">
              Join Us
            </button>
          </div>
        </div>
      </div> */}
      <div className="container p-0">
        <div className="row mt-5">
          <div className="col-sm-6 col-12 order-sm-1 order-2 d-flex align-items-center ">
            <AnimationOnScroll
              className={"lead-text"}
              delay={300}
              animateOnce={true}
              animateIn="animate__fadeInLeft"
            >
              <div className=" ms-lg-2 ms-0 mb-5">
                <h1 className="fw-bold text-light">Advancing AI In Pakistan</h1>
                <p className="text-light">
                  We are leading the AI Revolution in Pakistan, educating the
                  next generation Of Pakistani AI researchers and innovators
                </p>

                <a
                  href="/membership"
                  type="button"
                  id="herobtn"
                  className="btn btn-lg text-light text-center me-auto"
                >
                  Join Us
                </a>
              </div>
            </AnimationOnScroll>
          </div>
          <div
            className="col-sm-6 order-sm-2 order-1 col-12"
            style={{ height: "80vh" }}
          ></div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
