import React from "react";
import "./ProjectsPage.css";
import { NavLink, useLocation } from "react-router-dom";
import Banner from "../../components/banner/Banner.js"
import { img_projects_desktop, img_projects_mobile } from "../../constants/image_constants";

function ProjectPage() {

  function ShowProject() {

    const location=useLocation();
    const state=location.state;

    return (
      <section className={"card-container"} key={state.id}>
        <div className={"card-image"}>
          <img src={state.image} />
        </div>
        <div className={"card-text"}>
          <h1>{state.title}</h1>
          <p><small>{state.dateOfCreation}</small></p>
          <p>{state.summary}</p>
          <NavLink to="/projects" className="btn btn-outline-dark">
            Go Back to Projects
          </NavLink>
        </div>
      </section>
    );
  }

  return (
    <div className="projectCard">
      {/* Banner */}
      <Banner
        title="Project"
        img={{ desktop: img_projects_desktop, mobile: img_projects_mobile }}
        titleClasses="projects-banner"
      />
      {/* Project Card */}
      <ShowProject />
    </div>
  );
}
export default ProjectPage;
