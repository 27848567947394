import { get, post } from "../axiosConfig";
// services object that handle Blog APIs
// each propery in the object is a function that uses the axios http request method (get, post, put, etc)
const eventsServices = {
  getEventsList: (page, size) => {
    return get("events", {
      params: { page, size },
    });
  },
  getEvent: (id) => {
    return get(`events/${id}`);
  },
  getModules: (id) => {
    return get(`events/${id}/modules`);
  },
  getForm: (id) => {
    return get(`forms/${id}`);
  },
  addResponseToForm: (id, form) => {
    return post(`forms/${id}/responses`, form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};

export default eventsServices;
