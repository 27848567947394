import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import DynamicForm from "../../components/dynamicForm/DynamicForm";
import Banner from "../../components/banner/Banner";
import "./EventFormPage.css";
import {
  img_events_desktop,
  img_events_mobile,
} from "../../constants/image_constants";
import { useDispatch, useSelector } from "react-redux";
import {
  addResponseToEventForm,
  getEventForm,
  resetEventState,
} from "../../redux/slices/event.slice";
import EventFormCards from "./EventFormCards";
import CustomModal from "../../components/modal/Modal";

const WorkshopForm = ({ form, setShowModal, setModalProps }) => {
  const [isWorkshoploading, setWorkshoploading] = useState(false);
  const [formState, setFormState] = useState({});
  const dispatch = useDispatch();
  const validate = () => {
    if (!formState || Object.keys(formState).length < 0) {
      setModalProps((prevState) => ({
        ...prevState,
        title: "ERROR",
        text: `Required fields not filled. Please make sure to enter valid data`,
      }));
      setShowModal(true);
      return false;
    }
    const check = form?.fields?.every((field) => {
      const isRequired = field.required;
      if (isRequired && !formState[field.name]) {
        setModalProps((prevState) => ({
          ...prevState,
          title: "ERROR",
          text: `${field.label} is Required. Please make sure to enter valid data`,
        }));
        setShowModal(true);
        return false;
      }
      return true;
    });
    return check;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Show the loader
    setWorkshoploading(true);
    if (validate()) {
      const formData = new FormData();
      Object.keys(formState).forEach((key) => {
        if (Array.isArray(formState[key])) {
          formData.append(key, JSON.stringify(formState[key]));
        } else {
          formData.append(key, formState[key]);
        }
      });
      dispatch(addResponseToEventForm({ id: form._id, form: formData }));
    }
    // Hide the loader
    setWorkshoploading(false);
  };

  const handleOnChange = ({ target, target: { value, name, type, files } }) => {
    setFormState((prevState) => {
      let newState = { ...prevState };
      delete newState[`${name}_other`];
      return newState;
    });
    if (type === "checkbox") {
      let fieldSet = target.closest("fieldset");
      let checkboxName = fieldSet.name;
      let valuesSelected = formState[checkboxName] || [];
      let index = valuesSelected?.length ? valuesSelected.indexOf(value) : -1;
      if (index === -1) {
        valuesSelected.push(value); // add value
      } else {
        valuesSelected.splice(index, 1); // remove value
      }
      setFormState((prevState) => ({
        ...prevState,
        [checkboxName]: valuesSelected,
      }));
    } else if (type === "file") {
      setFormState((prevState) => ({ ...prevState, [name]: files[0] }));
    } else {
      setFormState((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  return (
    <div className={"registration-form"}>
      <form onSubmit={handleSubmit}>
        <h5 className="fs-2 my-5 fw-bold">Registration Form</h5>
        <br />
        <div className="container px-4 px-md-5">
          <div className="row">
            <div className="col-12 col-md-6 col-xl-6 comp-rows my-3 flex-column ">
              <label className="labelstyle" htmlFor="first_name">
                First Name
              </label>
              <input
                onChange={handleOnChange}
                className="form-control"
                type="text"
                id="fname"
                name="first_name"
              />
            </div>
            <br />
            <div className="col-12 col-md-6 col-xl-6 comp-rows my-3 flex-column ">
              <label className="labelstyle" htmlFor="last_name">
                Last Name
              </label>
              <input
                onChange={handleOnChange}
                className="form-control"
                type="text"
                id="lname"
                name="last_name"
              />
            </div>
            <br />
          </div>

          <div className="row">
            <div className="col-12 col-md-6 col-xl-6 comp-rows my-3 flex-column ">
              <label className="labelstyle" htmlFor="department">
                Department/Major
              </label>
              <input
                onChange={handleOnChange}
                className="form-control"
                type="text"
                id="edu_ins"
                name="department_or_major"
              />
            </div>

            <div className="col-12 col-md-6 col-xl-6 comp-rows my-3 flex-column ">
              <label className="labelstyle" htmlFor="educational_institute">
                Educational Institute
              </label>
              <input
                onChange={handleOnChange}
                className="form-control"
                type="text"
                id="edu_ins"
                name="educational_institute"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-6 col-xl-6 comp-rows my-3 flex-column ">
              <label className="labelstyle" htmlFor="contact_number">
                Contact No.
              </label>
              <input
                onChange={handleOnChange}
                className="form-control"
                type="text"
                id="contact"
                name="contact_number"
              />
            </div>
            <br />
            <div className="col-12 col-md-6 col-xl-6 comp-rows my-3 flex-column ">
              <label className="labelstyle" htmlFor="year">
                Year
              </label>
              <input
                onChange={handleOnChange}
                className="form-control"
                type="text"
                id="year"
                name="year"
              />
            </div>
          </div>
        </div>

        <div className="container my-5 px-4 px-md-5">
          <label className="my-2 labelstyle">Workshop Registration</label>
          <br />
          <fieldset name="workshops">
            <input
              onChange={handleOnChange}
              type="checkbox"
              id="machine_learning"
              name="machine_learning"
              value="Machine Learning"
            />
            &nbsp;
            <label>Machine Learning by PIAIC</label>
            <br />
            <input
              onChange={handleOnChange}
              type="checkbox"
              id="business_intelligence"
              name="business_intelligence"
              value="Business Intelligence"
            />
            &nbsp;
            <label>Business Intelligence & Power BI Workshop by Farah Mehboob</label>
            <br />
            <input
              onChange={handleOnChange}
              type="checkbox"
              id="flutter"
              name="flutter"
              value="Flutter"
            />
            &nbsp;
            <label>Flutter by Sakina Abbas</label>
            <br />
          </fieldset>
        </div>

        <div className="container px-4 px-md-5 my-5">
          <label className="labelstyle" htmlFor="coupon">
            Coupon Code / Brand Ambassador Name (If you have both use given
            format)
          </label>
          <br />
          <input
            onChange={handleOnChange}
            className="form-control"
            placeholder="XYZ123 - Ambassador Name"
            type="text"
            id="coupon"
            name="coupon_code"
          />
        </div>

        <div className="container px-4 px-md-5 my-3">
          <label className="labelstyle" htmlFor="fee">
            Please attach the receipt of the paid registration fee. Make sure
            your image is in PNG, JPG, or JPEG format.
            <sup>
              <b>*</b>
            </sup>
          </label>
          <br />
          <input
            onChange={handleOnChange}
            type="file"
            className="form-control"
            id="fee"
            name="payslip"
          />
        </div>

        <div className="container d-flex justify-content-center align-items-center flex-column my-5">
          {
            isWorkshoploading ? (
              <div className="loading-div">
                <div class="loader"></div> Loading...
              </div>
            ) : (
              <input type="submit" className="btn" id="submit" value="Submit" />
            )
          }
          <label className="mt-5 text-center">
            You will recieve confirmation email within 24 hours.
          </label>
          <label className="mt-1 text-center">
            For any queries contact us at: <b>0344-2135254</b>
          </label>
        </div>
      </form>
    </div>
  );
};

// const RegisterationForm = ({ form, id, setShowModal, setModalProps }) => {

//   const [loading, setLoading] = useState(false)

//   const [isDisabled, setIsDisabled] = useState({
//     edu: [true, true, true, true, true],
//     edu_inst: [true, true, true, true, true],
//   });
//   const [formState, setFormState] = useState();
//   const dispatch = useDispatch();
//   const [renderControl, setRenderControl] = useState({
//     education_member_2: false,
//     education_member_3: false,
//     education_member_4: false,
//     education_member_5: false,
//   });

//   const enableOther = (type, index) => {
//     setIsDisabled((prevState) => ({
//       ...prevState,
//       [type]: [
//         ...prevState.edu.slice(0, index),
//         false,
//         ...prevState.edu.slice(index + 1),
//       ],
//     }));
//   };

//   const disableOther = (type, index) => {
//     setIsDisabled((prevState) => ({
//       ...prevState,
//       [type]: [
//         ...prevState.edu.slice(0, index),
//         true,
//         ...prevState.edu.slice(index + 1),
//       ],
//     }));
//   };

//   const validate = () => {
//     if (!formState || Object.keys(formState).length < 0) {
//       setModalProps((prevState) => ({
//         ...prevState,
//         title: "ERROR",
//         text: `Required fields not filled. Please make sure to enter valid data`,
//       }));
//       setShowModal(true);
//       return false;
//     }
//     const check = form?.fields?.every((field) => {
//       const isRequired = field.required;
//       if (isRequired && !formState[field.name]) {
//         setModalProps((prevState) => ({
//           ...prevState,
//           title: "ERROR",
//           text:
//             field.fieldType === "file"
//               ? field.label
//               : `${field.label} is Required. Please make sure to enter valid data`,
//         }));
//         setShowModal(true);
//         return false;
//       }
//       return true;
//     });
//     return check;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     // Show the loader
//     setLoading(true);
//     if (validate()) {
//       const formData = new FormData();
//       Object.keys(formState).forEach((key) => {
//         if (Array.isArray(formState[key])) {
//           formData.append(key, JSON.stringify(formState[key]));
//         } else {
//           formData.append(key, formState[key]);
//         }
//       });
//       await dispatch(addResponseToEventForm({ id, form: formData }));
//     }
//     // Hide the loader
//     setLoading(false);
//   };

//   const handleRenderControl = ({ newFormState }) => {
//     const memberKeys = [
//       "education_member_2",
//       "education_member_3",
//       "education_member_4",
//       "education_member_5",
//     ];
//     newFormState &&
//       Object.keys(newFormState).forEach((key) => {
//         const educationKey = `education_${key}`;
//         const eduKeyOther = `education_${key}_other`;
//         const educationInstKey = `educational_institute_${key}`;
//         const eduInsKeyOther = `educational_institute_${key}_other`;
//         if (memberKeys.includes(educationKey) && newFormState[key]) {
//           setRenderControl((prevState) => ({
//             ...prevState,
//             [educationKey]: true,
//           }));
//         } else {
//           setRenderControl((prevState) => ({
//             ...prevState,
//             [educationKey]: false,
//           }));
//           setFormState(() => {
//             let newState = { ...newFormState };
//             delete newState[educationKey];
//             delete newState[educationInstKey];
//             delete newState[eduKeyOther];
//             delete newState[eduInsKeyOther];
//             return newState;
//           });
//         }
//       });
//   };

//   const handleOnChange = ({
//     disableType,
//     i,
//     target,
//     target: { value, name, type, files },
//   }) => {
//     if (type === "radio") {
//       if (value === "Other") {
//         enableOther(disableType, i);
//       } else {
//         disableOther(disableType, i);
//         setFormState((prevState) => {
//           let newState = { ...prevState };
//           delete newState[`${name}_other`];
//           return newState;
//         });
//       }
//     }
//     if (type === "checkbox") {
//       let fieldSet = target.closest("fieldset");
//       let checkboxName = fieldSet.name;
//       let valuesSelected = formState[checkboxName] || [];
//       let index = valuesSelected?.length ? valuesSelected.indexOf(value) : -1;
//       if (index === -1) {
//         valuesSelected.push(value); // add value
//       } else {
//         valuesSelected.splice(index, 1); // remove value
//       }
//       setFormState((prevState) => ({
//         ...prevState,
//         [checkboxName]: valuesSelected,
//       }));
//     } else if (type === "file") {
//       setFormState((prevState) => ({ ...prevState, [name]: files[0] }));
//     } else {
//       const newFormState = { ...formState, [name]: value };
//       setFormState(newFormState);
//       handleRenderControl({ newFormState });
//     }
//   };

//   return (
//     <div className={"registration-form"}>
//       <form onSubmit={handleSubmit}>
//         <h5 className="fs-3 mt-5 fw-bold">Registration Form</h5>
//         {/* Team names and their emails*/}
//         <fieldset className="p-4 p-md-5">
//           <div className="row">
//             <div className="col-12 col-md-6 col-xl-6 comp-rows my-2 flex-column flex-md-row">
//               <label className="w-50 labelstyle" htmlFor="team_name">
//                 Team Name
//                 <sup>
//                   <b>*</b>
//                 </sup>
//               </label>
//               <input
//                 onChange={handleOnChange}
//                 className="form-control"
//                 type="text"
//                 id="name"
//                 name="team_name"
//               />
//             </div>
//             <div className="col-12 col-md-6 col-xl-6 comp-rows my-2 flex-column flex-md-row">
//               <label className="labelstyle" htmlFor="email">
//                 Email
//                 <sup>
//                   <b>*</b>
//                 </sup>
//               </label>
//               <input
//                 onChange={handleOnChange}
//                 className="form-control mx-md-3"
//                 type="email"
//                 id="email"
//                 name="email"
//               />
//             </div>
//           </div>
//         </fieldset>
//         <br />
//         {/* Competition registrations */}
//         <div className="container px-4 px-md-5">
//           <label className="mb-2 labelstyle">
//             Competitions
//             <sup>
//               <b>*</b>
//             </sup>
//           </label>
//           <br />
//           <fieldset name="competition_registeration">
//             <div className="row mb-4">
//               <div className="col-12 col-md-4 col-xl-4 comp-rows">
//                 <div>
//                   <label>Beginner</label>
//                   <br />
//                   <input
//                     onChange={handleOnChange}
//                     type="checkbox"
//                     id="app_dev"
//                     name="app_development"
//                     value="App Development (Beginner)"
//                   />
//                   &nbsp;
//                   <label htmlFor="app_development">App Development</label>
//                   <br />
//                   <input
//                     onChange={handleOnChange}
//                     type="checkbox"
//                     id="code_in_dark"
//                     name="code_in_the_dark"
//                     value="Code In The Dark (General)"
//                   />
//                   &nbsp;
//                   <label htmlFor="code_in_the_dark">Code In The Dark</label>
//                   <br />
//                   <input
//                     onChange={handleOnChange}
//                     type="checkbox"
//                     id="web_dev"
//                     name="website_development"
//                     value="Website Development (Beginner)"
//                   />
//                   &nbsp;
//                   <label htmlFor="website_development">Website Development</label>
//                   <br />
//                   <input
//                     onChange={handleOnChange}
//                     type="checkbox"
//                     id="speed_program"
//                     name="speed_programming"
//                     value="Speed Programming (Beginner)"
//                   />
//                   &nbsp;
//                   <label htmlFor="speed_programming">Speed Programming</label>
//                   <br />
//                   <input
//                     onChange={handleOnChange}
//                     type="checkbox"
//                     id="speed_debug"
//                     name="speed_debugging"
//                     value="Speed Debugging (Beginner)"
//                   />
//                   &nbsp;
//                   <label htmlFor="speed_debugging">Speed Debugging</label>
//                   <br />
//                   <input
//                     onChange={handleOnChange}
//                     type="checkbox"
//                     id="gpt_war"
//                     name="gpt_war"
//                     value="GPT War (Beginner)"
//                   />
//                   &nbsp;
//                   <label htmlFor="gpt_war">GPT War</label>
//                 </div>
//               </div>

//               <div className="col-12 col-md-4 col-xl-4 comp-rows">
//                 <div>
//                   <label>Advanced</label>
//                   <br />
//                   <input
//                     onChange={handleOnChange}
//                     type="checkbox"
//                     id="data_science_and_ml"
//                     name="data_science_and_machine_learning"
//                     value="Data Science and Machine Learning (Advanced)"
//                   />
//                   &nbsp;
//                   <label htmlFor="data_science_and_machine_learning">
//                     Data Science & ML
//                   </label>
//                   <br />
//                   <input
//                     onChange={handleOnChange}
//                     type="checkbox"
//                     id="app_dev"
//                     name="app_development"
//                     value="App Development (Advanced)"
//                   />
//                   &nbsp;
//                   <label htmlFor="app_development">App Development</label>
//                   <br />
//                   <input
//                     onChange={handleOnChange}
//                     type="checkbox"
//                     id="web_dev"
//                     name="website_development"
//                     value="Website Development (Advanced)"
//                   />
//                   &nbsp;
//                   <label htmlFor="website_development">
//                     Website Development
//                   </label>
//                   <br />
//                   <input
//                     onChange={handleOnChange}
//                     type="checkbox"
//                     id="data_analytics"
//                     name="data_analytics"
//                     value="Data Analytics (Advanced)"
//                   />
//                   &nbsp;
//                   <label htmlFor="data_analytics">Data Analytics</label>
//                   <br />
//                   <input
//                     onChange={handleOnChange}
//                     type="checkbox"
//                     id="game_dev"
//                     name="game_development"
//                     value="Game Development (Advanced)"
//                   />
//                   &nbsp;
//                   <label htmlFor="game_development">Game Development</label>
//                   <br />
//                   <input
//                     onChange={handleOnChange}
//                     type="checkbox"
//                     id="cv_war"
//                     name="computer_vision_war"
//                     value="Computer Vision War (Advanced)"
//                   />
//                   &nbsp;
//                   <label htmlFor="computer_vision_war">
//                     Computer Vision War
//                   </label>
//                 </div>
//               </div>

//               <div className="col-12 col-md-4 col-xl-4 comp-rows">
//                 <div>
//                   <label>General</label>
//                   <br />
//                   <input
//                     onChange={handleOnChange}
//                     type="checkbox"
//                     id="ui_ux"
//                     name="ui_ux"
//                     value="UI/UX (General)"
//                   />
//                   &nbsp;
//                   <label htmlFor="ui_ux">UI/UX</label>
//                   <br />
//                   <input
//                     onChange={handleOnChange}
//                     type="checkbox"
//                     id="logo_design"
//                     name="logo_designing"
//                     value="Logo Designing (General)"
//                   />
//                   &nbsp;
//                   <label htmlFor="logo_designing">Logo Designing</label>
//                   <br />
//                   <input
//                     onChange={handleOnChange}
//                     type="checkbox"
//                     id="photo"
//                     name="photography"
//                     value="Photography (General)"
//                   />
//                   &nbsp;
//                   <label htmlFor="photography">Photography</label>
//                   <br />
//                   <input
//                     onChange={handleOnChange}
//                     type="checkbox"
//                     id="video"
//                     name="videography"
//                     value="Videography (General)"
//                   />
//                   &nbsp;
//                   <label htmlFor="videography">Videography</label>
//                   <br />
//                   <input
//                     onChange={handleOnChange}
//                     type="checkbox"
//                     id="blogs"
//                     name="blogathon"
//                     value="Blogathon (General)"
//                   />
//                   &nbsp;
//                   <label htmlFor="blogathon">Blogathon</label>
//                 </div>
//               </div>
//             </div>

//             <div className="row">
//               <div className="col-12 col-md-4 col-xl-4 comp-rows">
//                 <div>
//                   <label>Robotics</label>
//                   <br />
//                   <input
//                     onChange={handleOnChange}
//                     type="checkbox"
//                     id="lfr"
//                     name="lfr_robotics"
//                     value="LFR (Robotics)"
//                   />
//                   &nbsp;
//                   <label htmlFor="lfr_robotics">LFR (Robotics)</label>
//                   <br />
//                   <input
//                     onChange={handleOnChange}
//                     type="checkbox"
//                     id="robo_war"
//                     name="robo_war"
//                     value="Robo War (Robotics)"
//                   />
//                   &nbsp;
//                   <label htmlFor="robo_war">Robo War</label>
//                   <br />
//                   <input
//                     onChange={handleOnChange}
//                     type="checkbox"
//                     id="robo_race"
//                     name="robo_race"
//                     value="Robo Race (Robotics)"
//                   />
//                   &nbsp;
//                   <label htmlFor="robo_race">Robo Race</label>
//                   <br />
//                   <input
//                     onChange={handleOnChange}
//                     type="checkbox"
//                     id="robo_soccer"
//                     name="robo_soccer"
//                     value="Robo Soccer (Robotics)"
//                   />
//                   &nbsp;
//                   <label htmlFor="robo_soccer">Robo Soccer</label>
//                 </div>
//               </div>
//               <div className="col-12 col-md-4 col-xl-4 comp-rows">
//                 <div>
//                   <label>E-Sports</label>
//                   <br />
//                   <input
//                     onChange={handleOnChange}
//                     type="checkbox"
//                     id="csgo"
//                     name="csgo"
//                     value="CSGO (E Sports)"
//                   />
//                   &nbsp;
//                   <label htmlFor="csgo">CSGO</label>
//                   <br />
//                   <input
//                     onChange={handleOnChange}
//                     type="checkbox"
//                     id="cs_1_6"
//                     name="cs_1_6"
//                     value="CS 1.6 (E Sports)"
//                   />
//                   &nbsp;
//                   <label htmlFor="cs_1_6">CS 1.6</label>
//                   <br />
//                   <input
//                     onChange={handleOnChange}
//                     type="checkbox"
//                     id="fifa"
//                     name="fifa"
//                     value="FIFA (E Sports)"
//                   />
//                   &nbsp;
//                   <label htmlFor="fifa">FIFA</label>
//                   <br />
//                   <input
//                     onChange={handleOnChange}
//                     type="checkbox"
//                     id="valorant"
//                     name="valorant"
//                     value="Valorant (E Sports)"
//                   />
//                   &nbsp;
//                   <label htmlFor="valorant">Valorant</label>
//                   <br />
//                   <input
//                     onChange={handleOnChange}
//                     type="checkbox"
//                     id="tekken"
//                     name="tekken"
//                     value="Tekken (E Sports)"
//                   />
//                   &nbsp;
//                   <label htmlFor="tekken">Tekken</label>
//                 </div>
//               </div>
//             </div>
//           </fieldset>

//           {/* Team members */}
//           <label className="mt-5 mb-2 labelstyle">
//             <b>Team Members</b>
//           </label>
//           <fieldset>
//             <div className="row mb-4">
//               <div className="col-12 col-md-4 col-xl-4 comp-rows">
//                 <div>
//                   <label htmlFor="member1">
//                     Member 1
//                     <sup>
//                       <b>*</b>
//                     </sup>
//                   </label>
//                   &nbsp;
//                   <input
//                     onChange={handleOnChange}
//                     className="form-control"
//                     type="text"
//                     id="member1"
//                     name="member_1"
//                   />
//                 </div>
//               </div>

//               <div className="col-12 col-md-4 col-xl-4 comp-rows">
//                 <div>
//                   <label htmlFor="member2">Member 2</label>
//                   &nbsp;
//                   <input
//                     onChange={handleOnChange}
//                     className="form-control"
//                     type="text"
//                     id="member2"
//                     name="member_2"
//                   />
//                 </div>
//               </div>
//               <div className="col-12 col-md-4 col-xl-4 comp-rows">
//                 <div>
//                   <label htmlFor="member3">Member 3</label>
//                   &nbsp;
//                   <input
//                     onChange={handleOnChange}
//                     className="form-control"
//                     type="text"
//                     id="member3"
//                     name="member_3"
//                   />
//                 </div>
//               </div>
//             </div>
//             <div className="row">
//               <div className="col-12 col-md-4 col-xl-4 comp-rows">
//                 <div>
//                   <label htmlFor="member4">Member 4</label>&nbsp;&nbsp;
//                   <input
//                     onChange={handleOnChange}
//                     className="form-control"
//                     type="text"
//                     id="member4"
//                     name="member_4"
//                   />
//                 </div>
//               </div>
//               <div className="col-12 col-md-4 col-xl-4 comp-rows">
//                 <div>
//                   <label htmlFor="member5">Member 5</label>&nbsp;&nbsp;
//                   <input
//                     onChange={handleOnChange}
//                     className="form-control"
//                     type="text"
//                     id="member5"
//                     name="member_5"
//                   />
//                 </div>
//               </div>
//             </div>
//           </fieldset>

//           {/* Education */}
//           <label className="mt-5 mb-2 labelstyle">
//             <b>Education</b>
//           </label>

//           <div className="row mb-4">
//             <div className="col-12 col-md-4 col-xl-4 comp-rows">
//               <div>
//                 <label>
//                   Member 1
//                   <sup>
//                     <b>*</b>
//                   </sup>
//                 </label>
//                 <br />
//                 <input
//                   onChange={({ target }) => {
//                     handleOnChange({ disableType: "edu", i: 0, target });
//                   }}
//                   type="radio"
//                   id="olevels"
//                   name="education_member_1"
//                   value="O Levels / Matriculation"
//                 />
//                 &nbsp;
//                 <label htmlFor="olevels">O Levels / Matriculation</label>
//                 <br />
//                 <input
//                   onChange={({ target }) => {
//                     handleOnChange({ disableType: "edu", i: 0, target });
//                   }}
//                   type="radio"
//                   id="alevels"
//                   name="education_member_1"
//                   value="A Levels / Intermediate"
//                 />
//                 &nbsp;
//                 <label htmlFor="alevels">A Levels / Intermediate</label>
//                 <br />
//                 <input
//                   onChange={({ target }) => {
//                     handleOnChange({ disableType: "edu", i: 0, target });
//                   }}
//                   type="radio"
//                   id="undergrad"
//                   name="education_member_1"
//                   value="Undergraduate Student"
//                 />
//                 &nbsp;
//                 <label htmlFor="undergrad">Undergraduate Student</label>
//                 <br />
//                 <input
//                   onChange={({ target }) => {
//                     handleOnChange({ disableType: "edu", i: 0, target });
//                   }}
//                   type="radio"
//                   id="postgrad"
//                   name="education_member_1"
//                   value="Postgraduate Student"
//                 />
//                 &nbsp;
//                 <label htmlFor="postgrad">Postgraduate Student</label>
//                 <br />
//                 <input
//                   onChange={({ target }) => {
//                     handleOnChange({ disableType: "edu", i: 0, target });
//                   }}
//                   type="radio"
//                   id="other"
//                   name="education_member_1"
//                   value="Other"
//                 />
//                 &nbsp;
//                 <label htmlFor="other">Other</label>
//                 <input
//                   onChange={({ target }) => {
//                     handleOnChange({ disableType: "edu", i: 0, target });
//                   }}
//                   className="form-control"
//                   type="text"
//                   name="education_member_1_other"
//                   id="education_member_1_other"
//                   disabled={isDisabled.edu[0]}
//                 />
//               </div>
//             </div>
//             {renderControl.education_member_2 && (
//               <div className="col-12 col-md-4 col-xl-4 comp-rows">
//                 <div>
//                   <label>Member 2</label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu", i: 1, target });
//                     }}
//                     type="radio"
//                     id="olevels"
//                     name="education_member_2"
//                     value="O Levels"
//                   />
//                   &nbsp;
//                   <label htmlFor="olevels">O Levels / Matriculation</label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu", i: 1, target });
//                     }}
//                     type="radio"
//                     id="alevels"
//                     name="education_member_2"
//                     value="A Levels"
//                   />
//                   &nbsp;
//                   <label htmlFor="alevels">A Levels / Intermediate</label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu", i: 1, target });
//                     }}
//                     type="radio"
//                     id="undergrad"
//                     name="education_member_2"
//                     value="Undergraduate"
//                   />
//                   &nbsp;
//                   <label htmlFor="undergrad">Undergraduate Student</label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu", i: 1, target });
//                     }}
//                     type="radio"
//                     id="postgrad"
//                     name="education_member_2"
//                     value="Postgraduate"
//                   />
//                   &nbsp;
//                   <label htmlFor="postgrad">Postgraduate Student</label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu", i: 1, target });
//                     }}
//                     type="radio"
//                     id="other"
//                     name="education_member_2"
//                     value="Other"
//                   />
//                   &nbsp;
//                   <label htmlFor="other">Other</label>
//                   <input
//                     onChange={handleOnChange}
//                     className="form-control"
//                     type="text"
//                     name="education_member_2_other"
//                     id="education_member_2_other"
//                     disabled={isDisabled.edu[1]}
//                   />
//                 </div>
//               </div>
//             )}
//             {renderControl.education_member_3 && (
//               <div className="col-12 col-md-4 col-xl-4 comp-rows">
//                 <div>
//                   <label>Member 3</label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu", i: 2, target });
//                     }}
//                     type="radio"
//                     id="olevels"
//                     name="education_member_3"
//                     value="O Levels"
//                   />
//                   &nbsp;
//                   <label htmlFor="olevels">O Levels / Matriculation</label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu", i: 2, target });
//                     }}
//                     type="radio"
//                     id="alevels"
//                     name="education_member_3"
//                     value="A Levels"
//                   />
//                   &nbsp;
//                   <label htmlFor="alevels">A Levels / Intermediate</label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu", i: 2, target });
//                     }}
//                     type="radio"
//                     id="undergrad"
//                     name="education_member_3"
//                     value="Undergraduate"
//                   />
//                   &nbsp;
//                   <label htmlFor="undergrad">Undergraduate Student</label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu", i: 2, target });
//                     }}
//                     type="radio"
//                     id="postgrad"
//                     name="education_member_3"
//                     value="Postgraduate"
//                   />
//                   &nbsp;
//                   <label htmlFor="postgrad">Postgraduate Student</label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu", i: 2, target });
//                     }}
//                     type="radio"
//                     id="other"
//                     name="education_member_3"
//                     value="Other"
//                   />
//                   <label htmlFor="other">Other</label>&nbsp;
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu", i: 2, target });
//                     }}
//                     type="text"
//                     className="form-control"
//                     name="education_member_3_other"
//                     id="education_member_3_other"
//                     disabled={isDisabled.edu[2]}
//                   />
//                 </div>
//               </div>
//             )}
//           </div>

//           <div className="row">
//             {renderControl.education_member_4 && (
//               <div className="col-12 col-md-4 col-xl-4 comp-rows">
//                 <div>
//                   <label>Member 4</label>
//                   <br />
//                   <input
//                     type="radio"
//                     id="olevels"
//                     name="education_member_4"
//                     value="O Levels"
//                   />
//                   &nbsp;
//                   <label htmlFor="olevels">O Levels / Matriculation</label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu", i: 3, target });
//                     }}
//                     type="radio"
//                     id="alevels"
//                     name="education_member_4"
//                     value="A Levels"
//                   />
//                   &nbsp;
//                   <label htmlFor="alevels">A Levels / Intermediate</label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu", i: 3, target });
//                     }}
//                     type="radio"
//                     id="undergrad"
//                     name="education_member_4"
//                     value="Undergraduate"
//                   />
//                   &nbsp;
//                   <label htmlFor="undergrad">Undergraduate Student</label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu", i: 3, target });
//                     }}
//                     type="radio"
//                     id="postgrad"
//                     name="education_member_4"
//                     value="Postgraduate"
//                   />
//                   &nbsp;
//                   <label htmlFor="postgrad">Postgraduate Student</label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu", i: 3, target });
//                     }}
//                     type="radio"
//                     id="other"
//                     name="education_member_4"
//                     value="Other"
//                   />
//                   &nbsp;
//                   <label htmlFor="other">Other</label>
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu", i: 3, target });
//                     }}
//                     type="text"
//                     className="form-control"
//                     name="education_member_4_other"
//                     id="education_member_4_other"
//                     disabled={isDisabled.edu[3]}
//                   />
//                 </div>
//               </div>
//             )}
//             {renderControl.education_member_5 && (
//               <div className="col-12 col-md-4 col-xl-4 comp-rows">
//                 <div>
//                   <label>Member 5</label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu", i: 4, target });
//                     }}
//                     type="radio"
//                     id="olevels"
//                     name="education_member_5"
//                     value="O Levels / Matriculation"
//                   />
//                   <label htmlFor="olevels">O Levels / Matriculation</label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu", i: 4, target });
//                     }}
//                     type="radio"
//                     id="alevels"
//                     name="education_member_5"
//                     value="A Levels"
//                   />
//                   &nbsp;
//                   <label htmlFor="alevels">A Levels / Intermediate</label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu", i: 4, target });
//                     }}
//                     type="radio"
//                     id="undergrad"
//                     name="education_member_5"
//                     value="Undergraduate"
//                   />
//                   &nbsp;
//                   <label htmlFor="undergrad">Undergraduate Student</label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu", i: 4, target });
//                     }}
//                     type="radio"
//                     id="postgrad"
//                     name="education_member_5"
//                     value="Postgraduate"
//                   />
//                   &nbsp;
//                   <label htmlFor="postgrad">Postgraduate Student</label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu", i: 4, target });
//                     }}
//                     type="radio"
//                     id="education5"
//                     name="education_member_5"
//                     value="Other"
//                   />
//                   <label htmlFor="other">Other</label>&nbsp;
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu", i: 4, target });
//                     }}
//                     className="form-control"
//                     type="text"
//                     name="education_member_5_other"
//                     id="education_member_5_other"
//                     disabled={isDisabled.edu[4]}
//                   />
//                 </div>
//               </div>
//             )}
//           </div>

//           {/* Educational Institute */}
//           <label className="mt-5 mb-2 labelstyle">
//             <b>Educational Institute</b>
//           </label>

//           <div className="row mb-4">
//             <div className="col-12 col-md-6 col-xl-6 comp-rows">
//               <div>
//                 <label>
//                   Member 1
//                   <sup>
//                     <b>*</b>
//                   </sup>
//                 </label>
//                 <br />
//                 <input
//                   onChange={({ target }) => {
//                     handleOnChange({ disableType: "edu_inst", i: 0, target });
//                   }}
//                   type="radio"
//                   id="ned"
//                   name="educational_institute_member_1"
//                   value="NED"
//                 />
//                 <label htmlFor="ned">
//                   NED University of Engineering and Technology
//                 </label>
//                 <br />
//                 <input
//                   onChange={({ target }) => {
//                     handleOnChange({ disableType: "edu_inst", i: 0, target });
//                   }}
//                   type="radio"
//                   id="sirsyed"
//                   name="educational_institute_member_1"
//                   value="Syed"
//                 />
//                 &nbsp;
//                 <label htmlFor="sirsyed">
//                   Sir Syed University of Engineering and Technology
//                 </label>
//                 <br />
//                 <input
//                   onChange={({ target }) => {
//                     handleOnChange({ disableType: "edu_inst", i: 0, target });
//                   }}
//                   type="radio"
//                   id="dawood"
//                   name="educational_institute_member_1"
//                   value="Dawood"
//                 />
//                 <label htmlFor="dawood">
//                   Dawood University of Engineering and Technology
//                 </label>
//                 <br />
//                 <input
//                   onChange={({ target }) => {
//                     handleOnChange({ disableType: "edu_inst", i: 0, target });
//                   }}
//                   type="radio"
//                   id="hamdard"
//                   name="educational_institute_member_1"
//                   value="Hamdard"
//                 />
//                 &nbsp;
//                 <label htmlFor="hamdard">Hamdard University</label>
//                 <br />
//                 <input
//                   onChange={({ target }) => {
//                     handleOnChange({ disableType: "edu_inst", i: 0, target });
//                   }}
//                   type="radio"
//                   id="other"
//                   name="educational_institute_member_1"
//                   value="Other"
//                 />
//                 &nbsp;
//                 <label htmlFor="other">Other</label>&nbsp;
//                 <input
//                   onChange={({ target }) => {
//                     handleOnChange({ disableType: "edu_inst", i: 0, target });
//                   }}
//                   type="text"
//                   className="form-control"
//                   name="educational_institute_member_1_other"
//                   id="educational_institute_member_1_other"
//                   disabled={isDisabled.edu_inst[0]}
//                 />
//               </div>
//             </div>
//             {renderControl.education_member_2 && (
//               <div className="col-12 col-md-6 col-xl-6 comp-rows">
//                 <div>
//                   <label>Member 2</label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu_inst", i: 1, target });
//                     }}
//                     type="radio"
//                     id="ned"
//                     name="educational_institute_member_2"
//                     value="NED"
//                   />
//                   &nbsp;
//                   <label htmlFor="ned">
//                     NED University of Engineering and Technology
//                   </label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu_inst", i: 1, target });
//                     }}
//                     type="radio"
//                     id="sirsyed"
//                     name="educational_institute_member_2"
//                     value="Syed"
//                   />
//                   &nbsp;
//                   <label htmlFor="sirsyed">
//                     Sir Syed University of Engineering and Technology
//                   </label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu_inst", i: 1, target });
//                     }}
//                     type="radio"
//                     id="dawood"
//                     name="educational_institute_member_2"
//                     value="Dawood"
//                   />
//                   &nbsp;
//                   <label htmlFor="dawood">
//                     Dawood University of Engineering and Technology
//                   </label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu_inst", i: 1, target });
//                     }}
//                     type="radio"
//                     id="hamdard"
//                     name="educational_institute_member_2"
//                     value="Hamdard"
//                   />
//                   &nbsp;
//                   <label htmlFor="hamdard">Hamdard University</label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu_inst", i: 1, target });
//                     }}
//                     type="radio"
//                     id="other"
//                     name="educational_institute_member_2"
//                     value="Other"
//                   />
//                   &nbsp;
//                   <label htmlFor="other">Other</label>&nbsp;
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu_inst", i: 1, target });
//                     }}
//                     type="text"
//                     className="form-control"
//                     name="educational_institute_member_2_other"
//                     id="educational_institute_member_2_other"
//                     disabled={isDisabled.edu_inst[1]}
//                   />
//                 </div>
//               </div>
//             )}
//           </div>

//           <div className="row mb-4">
//             <div className="col-12 col-md-6 col-xl-6 comp-rows">
//               {renderControl.education_member_3 && (
//                 <div>
//                   <label>Member 3</label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu_inst", i: 2, target });
//                     }}
//                     type="radio"
//                     id="ned"
//                     name="educational_institute_member_3"
//                     value="NED"
//                   />
//                   &nbsp;
//                   <label htmlFor="ned">
//                     NED University of Engineering and Technology
//                   </label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu_inst", i: 2, target });
//                     }}
//                     type="radio"
//                     id="sirsyed"
//                     name="educational_institute_member_3"
//                     value="Syed"
//                   />
//                   &nbsp;
//                   <label htmlFor="sirsyed">
//                     Sir Syed University of Engineering and Technology
//                   </label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu_inst", i: 2, target });
//                     }}
//                     type="radio"
//                     id="dawood"
//                     name="educational_institute_member_3"
//                     value="Dawood"
//                   />
//                   &nbsp;
//                   <label htmlFor="dawood">
//                     Dawood University of Engineering and Technology
//                   </label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu_inst", i: 2, target });
//                     }}
//                     type="radio"
//                     id="hamdard"
//                     name="educational_institute_member_3"
//                     value="Hamdard"
//                   />
//                   &nbsp;
//                   <label htmlFor="hamdard">Hamdard University</label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu_inst", i: 2, target });
//                     }}
//                     type="radio"
//                     id="other"
//                     name="educational_institute_member_3"
//                     value="Other"
//                   />
//                   &nbsp;
//                   <label htmlFor="other">Other</label>&nbsp;
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu_inst", i: 2, target });
//                     }}
//                     type="text"
//                     className="form-control"
//                     name="educational_institute_member_3_other"
//                     id="educational_institute_member_3_other"
//                     disabled={isDisabled.edu_inst[2]}
//                   />
//                 </div>
//               )}
//             </div>
//             <div className="col-12 col-md-6 col-xl-6 comp-rows">
//               {renderControl.education_member_4 && (
//                 <div>
//                   <label>Member 4</label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu_inst", i: 3, target });
//                     }}
//                     type="radio"
//                     id="ned"
//                     name="educational_institute_member_4"
//                     value="NED"
//                   />
//                   &nbsp;
//                   <label htmlFor="ned">
//                     NED University of Engineering and Technology
//                   </label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu_inst", i: 3, target });
//                     }}
//                     type="radio"
//                     id="sirsyed"
//                     name="educational_institute_member_4"
//                     value="Syed"
//                   />
//                   &nbsp;
//                   <label htmlFor="sirsyed">
//                     Sir Syed University of Engineering and Technology
//                   </label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu_inst", i: 3, target });
//                     }}
//                     type="radio"
//                     id="dawood"
//                     name="educational_institute_member_4"
//                     value="Dawood"
//                   />
//                   &nbsp;
//                   <label htmlFor="dawood">
//                     Dawood University of Engineering and Technology
//                   </label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu_inst", i: 3, target });
//                     }}
//                     type="radio"
//                     id="hamdard"
//                     name="educational_institute_member_4"
//                     value="Hamdard"
//                   />
//                   &nbsp;
//                   <label htmlFor="hamdard">Hamdard University</label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu_inst", i: 3, target });
//                     }}
//                     type="radio"
//                     id="other"
//                     name="educational_institute_member_4"
//                     value="Other"
//                   />
//                   &nbsp;
//                   <label htmlFor="other">Other</label>&nbsp;
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu_inst", i: 3, target });
//                     }}
//                     type="text"
//                     className="form-control"
//                     name="educational_institute_member_4_other"
//                     id="educational_institute_member_4_other"
//                     disabled={isDisabled.edu_inst[3]}
//                   />
//                 </div>
//               )}
//             </div>
//           </div>
//           {renderControl.education_member_5 && (
//             <div className="row">
//               <div className="col-12 col-md-6 col-xl-6 comp-rows">
//                 <div>
//                   <label>Member 5</label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu_inst", i: 4, target });
//                     }}
//                     type="radio"
//                     id="ned"
//                     name="educational_institute_member_5"
//                     value="NED"
//                   />
//                   <label htmlFor="ned">
//                     NED University of Engineering and Technology
//                   </label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu_inst", i: 4, target });
//                     }}
//                     type="radio"
//                     id="sirsyed"
//                     name="educational_institute_member_5"
//                     value="Syed"
//                   />
//                   &nbsp;
//                   <label htmlFor="sirsyed">
//                     Sir Syed University of Engineering and Technology
//                   </label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu_inst", i: 4, target });
//                     }}
//                     type="radio"
//                     id="dawood"
//                     name="educational_institute_member_5"
//                     value="Dawood"
//                   />
//                   &nbsp;
//                   <label htmlFor="dawood">
//                     Dawood University of Engineering and Technology
//                   </label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu_inst", i: 4, target });
//                     }}
//                     type="radio"
//                     id="hamdard"
//                     name="educational_institute_member_5"
//                     value="Hamdard"
//                   />
//                   &nbsp;
//                   <label htmlFor="hamdard">Hamdard University</label>
//                   <br />
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu_inst", i: 4, target });
//                     }}
//                     type="radio"
//                     id="other"
//                     name="educational_institute_member_5"
//                     value="Other"
//                   />
//                   &nbsp;
//                   <label htmlFor="other">Other</label>&nbsp;
//                   <input
//                     onChange={({ target }) => {
//                       handleOnChange({ disableType: "edu_inst", i: 4, target });
//                     }}
//                     type="text"
//                     className="form-control"
//                     name="educational_institute_member_5_other"
//                     id="educational_institute_member_5_other"
//                     disabled={isDisabled.edu_inst[4]}
//                   />
//                 </div>
//               </div>
//             </div>
//           )}

//           {/* Coupon and fee */}
//           <label className="mt-5 mb-2 labelstyle" htmlFor="coupon">
//             Coupon Code / Brand Ambassador Name (If you have both use given
//             format)
//           </label>
//           <br />
//           <input
//             onChange={handleOnChange}
//             className="form-control"
//             placeholder="XYZ123 - Ambassador Name"
//             type="text"
//             id="coupon"
//             name="coupon_code"
//           />

//           <label className="mt-5 mb-2 labelstyle" htmlFor="fee">
//             Please attach the receipt of the paid registration fee. Make sure
//             your image is in PNG, JPG, or JPEG format.
//             <sup>
//               <b>*</b>
//             </sup>
//           </label>
//           <input
//             onChange={handleOnChange}
//             className="form-control"
//             type="file"
//             id="fee"
//             name="payslip"
//           />

//           <div className="container d-flex justify-content-center align-items-center flex-column my-5">
//             {
//               loading ? (
//                 <div className="loading-div">
//                   <div class="loader"></div> Loading...
//                 </div>
//               ) : (
//                 <input type="submit" className="btn" id="submit" value="Submit" />
//               )
//             }
//             <label className="mt-5 text-center">
//               You will recieve confirmation email within 24 hours.
//             </label>
//             <label className="mt-1 text-center">
//               For any queries contact us at: <b>0344-2135254</b>
//             </label>
//           </div>
//         </div>
//       </form>
//     </div>
//   );
// };

const BankDetails = () => (
  <>
    <p style={{ fontWeight: "bold" }}>Bank Details For Payment</p>
    <p>Account Name: WAJIH NAWAZ KHAN </p>
    <p>Address: Meezan Bank Water Pump Karachi</p>
    <p>Account Number: 01330105534206 </p>
    <p>IBAN: PK84MEZN0001330105534206</p>
    <br />
    <p>Easy Paisa:</p>
    <p>WAJHA NAWAZ KHAN </p>
    <p>03343304258</p>
    <br />
  </>
);

const EventFormPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    event: { modules },
    submissionResponse,
    error,
  } = useSelector(({ event }) => event);
  const index = modules?.findIndex((module) => module.formId == id);
  const [form, setForm] = useState({});
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [modalProps, setModalProps] = useState({
    title: "MODAL",
    text: "TEXT FOR MODAL",
    buttons: [
      {
        name: "OK",
        func: () => {
          setShowModal(false);
        },
      },
    ],
  });
  useEffect(() => {
    dispatch(getEventForm({ id }));
  }, []);

  useEffect(() => {
    index >= 0 && setForm(modules[index].form);
  }, [modules]);

  useEffect(() => {
    if (submissionResponse) {
      setModalProps((prevState) => ({
        ...prevState,
        title: "SUCCESS",
        text: "Your response as been submitted! We will email you a confirmation within 24 hours",
        buttons: [
          {
            name: "Exit To Events Page",
            func: () => {
              navigate("/events");
            },
          },
        ],
      }));
      setShowModal(true);
      dispatch(resetEventState());
    }
    if (error) {
      setModalProps((prevState) => ({
        ...prevState,
        title: "ERROR",
        text: "Your response could not be submitted due to a server error. Please make sure you have correctly filled all required fields.",
        buttons: [
          {
            name: "OK",
            func: () => {
              setShowModal(false);
            },
          },
        ],
      }));
      setShowModal(true);
    }
  }, [submissionResponse, error]);

  useEffect(() => {
    if (!id) {
      window.alert("Form Not Found");
      navigate("/events");
    }
  }, []);
  return (
    <div className={"eventFormPage"}>
      <Banner
        title={form?.title}
        img={{ desktop: img_events_desktop, mobile: img_events_mobile }}
      />
      <CustomModal
        props={{ show: showModal, setShow: setShowModal, ...modalProps }}
      />{" "}
      <section className="header">
        <div className="intro">
          {form?.title === "Workshop Registration" && (
            <>
              <h4>
                <b>About our workshops</b>
              </h4>
              <p>
                Are you ready to unlock the secrets of AI and unleash your
                creativity? Then join us at AI Fest 4.0 for immersive
                workshops/Seminars that will take you on an exciting journey
                into the world of artificial intelligence. Our expert
                instructors will equip you with the latest AI concepts, tools,
                and techniques and show you how to apply them to real-world
                scenarios. From theory to practice, you will gain hands-on
                experience in building intelligent systems that have the
                potential to revolutionize the future. Don't miss this
                opportunity to expand your knowledge, network with like-minded
                individuals, and become a part of the rapidly growing field of
                AI! Don't Just Learn About AI -Get Hands-On Experience and
                Become an AI Pro. Register Now for Our Exclusive Workshops at AI
                Fest 4.0!"
              </p>
            </>
          )}
        </div>
      </section>
      <section className="registration">
      {
        form?.title === "Workshop Registration" && (
          <div className="register">
          <h5>Register Now</h5>
          <p className="my-4">
            So, What are you waiting for? Register now and embark on an
            extraordinary adventure of innovation and creativity. The stage is
            set, the challenges await—will you rise to the occasion and become
            the next AI champion? Join AI Club's competitions and let the world
            witness your brilliance!
          </p>
          <BankDetails />
          <br />
        </div>
        )
      }
        {form?.title === "Competition Registration" && (
          // <RegisterationForm
          //   form={form}
          //   id={id}
          //   setShowModal={setShowModal}
          //   setModalProps={setModalProps}
          // />
          <div className="form-closed">
            <h2 style={{fontWeight:"bold"}}> REGISTERATION CLOSED </h2>
          </div>
        )}
        {form?.title === "Workshop Registration" && (
          <WorkshopForm
            form={form}
            setShowModal={setShowModal}
            setModalProps={setModalProps}
          />
        )}
      </section>
    </div>
  );
};

export default EventFormPage;
