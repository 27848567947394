import "./Modal.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";

const CustomModal = ({ props }) => {
  const { title, text, image, buttons, show = false, setShow } = props;
  // TODO: configure image aswell.

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false)
      }}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {title || "NOTICE"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{text || ""}</p>
      </Modal.Body>
      <Modal.Footer>
        {buttons?.map(({ func, name, ...props }) => (
          <Button onClick={func} {...props}>
            {name}
          </Button>
        ))}
      </Modal.Footer>
    </Modal>
  );

  // * For Reference
  // return (
  //   <>
  //     <div
  //       className="modal fade m-0"
  //       tabIndex="-1"
  //       aria-labelledby="exampleModalLabel"
  //       aria-hidden="true"
  //     >
  //       <div className={`modal-dialog modal-dialog-centered`}>
  //         <div className="modal-content bg-none p-2">
  //           <div className="modal-body text-center p-0">
  //             {image && (
  //               <div
  //                 id="img-container"
  //                 style={{ backgroundImage: `url(${image})` }}
  //               ></div>
  //             )}
  //             {title && (
  //               <h3
  //                 className="modal-title text-dark fw-bold mt-1 pb-0"
  //                 id="exampleModalLabel"
  //               >
  //                 {title}
  //               </h3>
  //             )}
  //             {text && (
  //               <p className="text-break text-dark fs-6 mt-1 pe-0">{text}</p>
  //             )}
  //           </div>
  //           <div className="modal-footer btn-group border-light p-0 pb-1 pt-1 d-flex justify-content-between">
  //             {buttons &&
  //               buttons.map((button) => {
  //                 return (
  //                   <a
  //                     key={button.name}
  //                     href={button.link}
  //                     onClick={button.func}
  //                     className="btn btn-primary shadow-none"
  //                     data-bs-dismiss={
  //                       button.link || button.func ? "" : "modal"
  //                     }
  //                   >
  //                     {button.name}
  //                   </a>
  //                 );
  //               })}
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );
};
export default CustomModal;
