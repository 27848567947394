export const img_team_excom = require("../images/teams/ourTeams1.jpeg");

export const img_no_profile = require("../images/no_profile_picture_icon.png");

export const aic_logo = require("../images/AIC-logo.png");

export const IMAGES_EXCOM = {
  president_werdah_hashmi: require("../images/teams/excom/president_werdah_hashmi.jpeg"),
  vice_president_hamna_khan: require("../images/teams/excom/vice_president_hamna_khan.jpeg"),
  technical_head_uzair_khan: require("../images/teams/excom/technical_head_uzair_khan.jpeg"),
  media_head_haris_khan: require("../images/teams/excom/media_head_haris_khan.jpeg"),
  general_secretary_wajih_khan: require("../images/teams/excom/general_secretary_wajih_khan.jpeg"),
};
export const IMAGES_CHAIRS = {
  chair_competitions_sana_mashood: require("../images/teams/chairs/chair_competitions_sana_mashood.jpg"),
  chair_corporate_mahnoor_zahid: require("../images/teams/chairs/chair_corporate_mahnoor_zahid.jpg"),
  chair_promotions_shiraz_waseem: require("../images/teams/chairs/chair_promotions_shiraz_waseem.jpg"),
  chair_events_abdul_ahad_sheikh: require("../images/teams/chairs/chair_events_abdul_ahad_sheikh.jpg"),
};

export const IMAGES_TEAMS = {
  WEB_DEVELOPMENT: {
    director_muhammad_erbaz_kamran: require("../images/teams/web_dev/director_muhammad_erbaz_kamran.jpeg"),
    deputy_director_abdul_bari: require("../images/teams/web_dev/deputy_director_abdul_bari.jpeg"),
    team_web_dev: require("../images/teams/web_dev/team_web_dev.jpg"),
  },

  TECHNICAL: {
    director_haniya_maqsood: require("../images/teams/technical/director_haniya_maqsood.jpeg"),
    deputy_director_moaaz_siddiqui: require("../images/teams/technical/deputy_director_moaaz_siddiqui.jpeg"),
    deputy_director_ayan_hussain: require("../images/teams/technical/deputy_director_ayan_hussain.jpeg"),
    team_technical: require("../images/teams/technical/team_technical.jpg"),
  },

  HR_AND_REGISTRATIONS: {
    director_tehreem_ekram: require("../images/teams/hr-and-registerations/director_tehreem_ekram.JPG"),
    deputy_director_minahil_tariq: require("../images/teams/hr-and-registerations/deputy_director_minahil_tariq.JPG"),
    team_hr_and_registeration: require("../images/teams/hr-and-registerations/team_hr_and_registeration.jpg"),
  },

  PROMOTIONS: {
    director_fariya_asif: require("../images/teams/promotions/director_fariya_asif.jpeg"),
    director_huma_afzal: require("../images/teams/promotions/director_huma_afzal.jpeg"),
    // deputy_director_ahmed_ubaidullah: require("../images/teams/promotions/deputy_director_ahmed_ubaidullah"),
    deputy_director_shah_hussain: require("../images/teams/promotions/deputy_director_shah_hussain.jpeg"),
    team_promotions: require("../images/teams/promotions/team_promotions.jpg"),
  },

  SOCIAL_MEDIA: {
    director_dayyan_javaid: require("../images/teams/social-media-and-marketing/director_dayyan_javaid.jpg"),
    deputy_director_maaz_mustafa: require("../images/teams/social-media-and-marketing/deputy_director_maaz_mustafa.jpg"),
    director_ammarah_awan_deputy: require("../images/teams/social-media-and-marketing/director_ammarah_awan_deputy.JPG"),
    team_social_media_and_marketing: require("../images/teams/social-media-and-marketing/team_social_media_and_marketing.jpg"),
  },

  MANAGEMENT_AND_LOGISTICS: {
    deputy_director_usaid_asif: require("../images/teams/mgmt_log/deputy_director_usaid_asif.jpg"),
    team_mgmt_log: require("../images/teams/mgmt_log/team_event_management_and_logistics.jpg"),
  },

  GRAPHICS: {
    director_ali_mukadam: require("../images/teams/graphics/director_ali_mukadam.JPG"),
    deputy_director_mehak_hussain: require("../images/teams/graphics/deputy_director_mehak_hussain.JPG"),
    deputy_director_zainab_nawaz: require("../images/teams/graphics/deputy_director_zainab_nawaz.JPG"),
    team_graphics: require("../images/teams/graphics/team_graphics.jpg"),
  },

  CORPORATE: {
    director_syeda_zainab_salahuddin: require("../images/teams/corporate/director_syeda_zainab_salahuddin.jpg"),
    team_corporate: require("../images/teams/corporate/team_corporate.jpg"),
  },

  CREATIVE: {
    director_mahwish_hashmi: require("../images/teams/creative/director_mahwish_hashmi.JPG"),
    deputy_director_hiba_khan: require("../images/teams/creative/deputy_director_hiba_khan.JPG"),
    team_creativity: require("../images/teams/creative/team_creativity.jpg"),
  },

  CONTENT_WRITING: {
    deputy_director_syed_aali: require("../images/teams/content_wt/deputy_director_syed_aali.JPG"),
    director_nizana_khan: require("../images/teams/content_wt/director_nizana_khan.JPG"),
    deputy_director_syeda_khadija_abidi: require("../images/teams/content_wt/deputy_director_syeda_khadija_abidi.JPG"),
    team_content_wt: require("../images/teams/content_wt/team_content_writing.jpg"),
  },
};

export const OUR_TEAMS = [
  { image: img_team_excom },
  { image: IMAGES_TEAMS.WEB_DEVELOPMENT.team_web_dev },
  { image: IMAGES_TEAMS.CONTENT_WRITING.team_content_wt },
  { image: IMAGES_TEAMS.TECHNICAL.team_technical },
  { image: IMAGES_TEAMS.MANAGEMENT_AND_LOGISTICS.team_mgmt_log },
  { image: IMAGES_TEAMS.GRAPHICS.team_graphics },
  { image: IMAGES_TEAMS.PROMOTIONS.team_promotions },
  { image: IMAGES_TEAMS.CREATIVE.team_creativity },
  { image: IMAGES_TEAMS.HR_AND_REGISTRATIONS.team_hr_and_registeration },
  { image: IMAGES_TEAMS.SOCIAL_MEDIA.team_social_media_and_marketing },
];

export const achievement_clipart = require("../images/achievement-clipart-2.png");
export const mission = require("../images/mission.jpg");
export const philosophy = require("../images/philosophy.jpg");
export const what_drives_us = require("../images/what-drives-us.png");

export const ABOUT = [
  { image: achievement_clipart },
  { image: mission },
  { image: philosophy },
  { image: what_drives_us },
];
export const img_membership_mobile = require("../images/membership/membership_mobile.png");
export const img_membership_desktop = require("../images/membership/membership_desktop.png");

export const img_membership = require("../images/membership/membership.jpg");
export const default_banner_img = require("../images/banners/banner-dark-2.jpg");

export const img_blogs_mobile = require("../images/blogs/blogs_banner_mobile.png");
export const img_blogs_desktop = require("../images/blogs/blogs_banner_desktop.png");

export const img_projects_desktop = require("../images/projects/projects_banner_desktop.png");
export const img_projects_mobile = require("../images/projects/projects_banner_mobile.png");

export const img_our_teams_desktop = require("../images/teams/our_teams_banner_desktop.png");
export const img_our_teams_mobile = require("../images/teams/our_teams_banner_mobile.png");

export const img_events_desktop = require("../images/events/events_banner_desktop.png");
export const img_events_mobile = require("../images/events/events_banner_mobile.png");

export const img_contactUs_desktop = require("../images/contactUs/contactUs_banner_desktop.png");
export const img_contactUs_mobile = require("../images/contactUs/contactUs_banner_mobile.png");

export const img_aboutUs_desktop = require("../images/aboutUs/aboutUs_banner_desktop.png");
export const img_aboutUs_mobile = require("../images/aboutUs/aboutUs_banner_mobile.png");

export const IMAGES_HOMEPAGE = {
  ABOUT_US: {
    about_us: require("../images/home/about_us.png"),
    our_mission: require("../images/home/our_mission.png"),
    our_vision: require("../images/home/our_vision.png"),
  },
  PROJECTS: {
    object_detector: require("../images/home/object_detector.jpeg"),
    text_detector: require("../images/home/text_detector.jpeg"),
    cow_detector: require("../images/home/cow_detector.jpeg"),
  },
  HOW_AIC_WORKS: {
    aic_festival: require("../images/home/aic_festival.png"),
    bootcamp: require("../images/home/bootcamp.png"),
    courses: require("../images/home/courses.png"),
  },
};

export const SPONSORS = [
  {
    logo: require("../images/sponsors/afiniti_pair_better_black.png"),
    name: "Afinti",
  },
  {
    logo: require("../images/sponsors/bleed_ai.png"),
    name: "Bleed AI",
  },
  {
    logo: require("../images/sponsors/cubix_logo.png"),
    name: "Cubix",
  },
  {
    logo: require("../images/sponsors/edvon.png"),
    name: "Edvon",
  },
  {
    logo: require("../images/sponsors/folio3.png"),
    name: "Folio3",
  },
  {
    logo: require("../images/sponsors/futurerality_final.png"),
    name: "Futurerality",
  },
  {
    logo: require("../images/sponsors/gaditek.png"),
    name: "Gaditek",
  },
  {
    logo: require("../images/sponsors/irvinei.jpeg"),
    name: "Irvinei",
  },
  {
    logo: require("../images/sponsors/karachi_ai_logo.png"),
    name: "Karachi AI",
  },
  {
    logo: require("../images/sponsors/mindstorm_engineering.png"),
    name: "Mindstorm Engineering",
  },
  {
    logo: require("../images/sponsors/mojo.png"),
    name: "Mojo",
  },
  {
    logo: require("../images/sponsors/ncai_smart_city.png"),
    name: "NCAI Smart City Lab",
  },
  {
    logo: require("../images/sponsors/ncai.png"),
    name: "NCAI",
  },
  {
    logo: require("../images/sponsors/piaic.png"),
    name: "PIAIC",
  },
  {
    logo: require("../images/sponsors/sifive.png"),
    name: "SiFive",
  },
  {
    logo: require("../images/sponsors/tech_station.png"),
    name: "Tech Station",
  },
];


export const Aifest4 = require('../images/Aifest4.png')