import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Banner from "../../components/banner/Banner";
import "./EventPage.css";
import { useDispatch, useSelector } from "react-redux";
import { getEvent, getEventModules } from "../../redux/slices/event.slice";
import {
  img_events_desktop,
  img_events_mobile,
} from "../../constants/image_constants";
import { ClipLoader } from "react-spinners";



const EventPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const eventState = useSelector(({ event }) => event);
  const { event, loader, error } = eventState;
  useEffect(() => {
    dispatch(getEvent({ id }));
    dispatch(getEventModules({ id }));
  }, []);

  useEffect(() => {
    if (error) {
      navigate("/events");
    }
  }, [eventState]);


  const toEventFormPage = (_id) => {
    window.scrollTo(0,0);
    navigate(`/events/${id}/form/${_id}`);
  }

  const showModules = event?.modules?.map((module) => {
    return (
      <section key={module._id} className="card" onClick={() => { toEventFormPage(module.formId) }}>
        <div className={"card-text"}></div>
        <h3>{module.title}</h3>
        <p className="eventPage-card-text">{module.details}</p>
      </section>
    );
  });


  // const eventStatus=JSON.stringify(event.status.number);
  return (
    <div className="eventPage">
      <Banner
        title={event?.title || ""}
        titleClasses={"events-banner"}
        img={{ desktop: img_events_desktop, mobile: img_events_mobile }}
      />
      {loader == false && event?._id ? (
        <div className="container">
          <div className="event-title">
            <h1>About {event?.title}</h1>
            <p style={{ color: "#0000007A", whiteSpace: "pre-line" }}>
              {event?.details}
            </p>
          </div>
          <div className="cards-container">{showModules}</div>
        </div>
      ) : (
        <div className="loader-container my-5">
          <ClipLoader size={100} loading={loader} color={"blue"} />
        </div>
      )}
    </div>
  );
};

export default EventPage;
