//this slice will hold all blogs the user creates or has accessed.
import blogServices from "../../axios/services/blog.service";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  blogs: [],
  page: 0,
  isLastPage: false,
  lastPage: 0,
  userBlogs: [],
  loader: false,
  error: null,
};

export const getBlogList = createAsyncThunk(
  "blog/getBlogList",
  async ({ page, size }, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const response = await blogServices.getBlogList(page, size); //blogs.length is the current number of records the user has saved
      if (Object.keys(response.data).length>0) {
        response.page = page + 1; // also add the next page value into the response
        return response.data;
      } else {
        return rejectWithValue({
          message: "ERROR",
          error: "Unable to find blogs",
        });
      }
    } catch (error) {
      return rejectWithValue({ error: error }); //response error
    }
  }
);

const blog = createSlice({
  name: "blog",
  initialState,
  reducers: {
    deleteBlogs: (state) => {
      state.blogs = [];
      state.userBlogs = [];
    },
    resetBlogState: () => ({...initialState})
  },
  extraReducers: (builder) => {
    builder.addCase(getBlogList.pending, (state)=>{
      state.loader = true;
    })
    builder.addCase(getBlogList.fulfilled, (state, { payload }) => {
      state.loader = false;
      state.blogs = [...state.blogs, ...payload.data];
      state.page = payload.next?.page || state.page
      state.isLastPage = payload.isLastPage;
      state.lastPage = payload.lastPage;
    });
    builder.addCase(getBlogList.rejected, (state, action) => {
      state.loader = false;
    });
  },
});

export const { deleteBlogs, resetBlogState } = blog.actions;

export default blog.reducer;
