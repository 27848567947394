import { useEffect } from "react";

export function useScrollToBottom(componentRef) {
  useEffect(() => {
    const handleScroll = () => {
      const node = componentRef.current;
      if (node.scrollHeight - node.scrollTop === node.clientHeight) {
      }
    };

    const node = componentRef.current;
    if (node) {
      node.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (node) {
        node.removeEventListener("scroll", handleScroll);
      }
    };
  }, [componentRef]);
}

export function useWindowScrolledToBottom() {
  const handleScroll = () => {
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
}
