import eventsServices from "../../axios/services/events.service";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  events: [],
  event: {},
  submissionResponse: "",
  error: null,
  loading: false,
};

export const getAllEventsList = createAsyncThunk(
  "events/getAll",
  async (_, thunkAPI) => {
    // WIP
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await eventsServices.getEventsList(); //blogs.length is the current number of records the user has saved
      if (Object.keys(response.data).length > 0) {
        return response.data;
      } else {
        return rejectWithValue({
          message: "ERROR",
          error: "Unable to find events list",
        });
      }
    } catch (error) {
      return rejectWithValue({ error }); //response error
    }
  }
);

export const getEvent = createAsyncThunk(
  "events/getEvent",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await eventsServices.getEvent(id);
      return response.data;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const getEventModules = createAsyncThunk(
  "events/getEventModules",
  async ({ id }, { rejectWithValue }) => {
    //id: event ID
    try {
      const response = await eventsServices.getModules(id);
      return response.data;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const getEventForm = createAsyncThunk(
  "events/getEventForm",
  async ({ id }, { rejectWithValue }) => {
    // id : form ID
    try {
      const response = await eventsServices.getForm(id);
      return response.data;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const addResponseToEventForm = createAsyncThunk(
  "events/addResponeToEventForm",
  async ({id, form}, {rejectWithValue}) => {
    //id: form ID, form: FormData()
    try {
      const response = await eventsServices.addResponseToForm(id, form);
      return response.data;
    } catch (error){
      return rejectWithValue({error});
    }
  }
)

const event = createSlice({
  name: "event",
  initialState,
  reducers: {
    resetEventState: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder.addCase(getAllEventsList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getAllEventsList.fulfilled, (state, { payload }) => {
      state.loader = false;
      state.events = payload.data;
      state.error = null;
    });
    builder.addCase(getAllEventsList.rejected, (state, action) => {
      state.loader = false;
    });

    builder.addCase(getEvent.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getEvent.fulfilled, (state, { payload }) => {
      state.loader = false;
      state.event = payload.data;
      state.error = null;
    });
    builder.addCase(getEvent.rejected, (state, {payload}) => {
      state.loader = false;
      state.error = payload.error
    });

    builder.addCase(getEventModules.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getEventModules.fulfilled, (state, { payload }) => {
      state.loader = false;
      state.event.modules = payload.data;
      state.error = null;
    });
    builder.addCase(getEventModules.rejected, (state) => {
      state.loader = false;
    });

    builder.addCase(getEventForm.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getEventForm.fulfilled, (state, { payload }) => {
      state.loader = false;
      const index = state.event.modules.findIndex(module => module.formId == payload.data._id)
      if(index >= 0){
       state.event.modules[index].form = payload.data;
       state.error = null;
      }
    });
    builder.addCase(getEventForm.rejected, (state) => {
      state.loader = false;
    });

    builder.addCase(addResponseToEventForm.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(addResponseToEventForm.fulfilled, (state, { payload }) => {
      state.loader = false;
      state.submissionResponse = payload.message;
      state.error = null;
    });
    builder.addCase(addResponseToEventForm.rejected, (state, {payload}) => {
      state.loader = false;
      state.error = payload;
      state.submissionResponse = "";

    });

  },
});
export const { resetEventState } = event.actions
export default event.reducer;
