import React, { useState } from "react";
import Banner from "../../components/banner/Banner";
import CustomModal from "../../components/modal/Modal";
import main from "../../images/membership/membership.jpg";
import { MEMBERSHIP_PAGE } from "../../constants/string_constants";
import { FONT_COLOR_BLUE } from "../../constants/color_constants";
import "./MembershipPage.css";

function MembershipPage() {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => {
    setShowModal(!showModal)
  }
  const buttons = [
    {
      name: "Email Us",
      func: () => {
        window.open(
          "https://mail.google.com/mail/?view=cm&fs=1&tf=1",
          "_blank",
          "noopener,noreferrer"
        );
      },
    },
    {
      name: "OK",
      func: toggleModal
    },
  ];

  return (
    <div>
      <CustomModal props={{show:showModal, setShow:setShowModal, buttons, ...MEMBERSHIP_PAGE.modal} } />
      <Banner
        title={MEMBERSHIP_PAGE.banner.title}
        titleClasses={"membership-banner"}
        img={MEMBERSHIP_PAGE.banner.img}
      />
      <div className="MembershipPage">
        <div className="card-image">
          <img src={main} alt="Join Us Now" />
        </div>
        <div className="card-text">
          <h2>{MEMBERSHIP_PAGE.secOne.title}</h2>
          <p>{MEMBERSHIP_PAGE.secOne.text}</p>
          <button
            type="button"
            className="btn btn-outline-dark"
            onClick={()=>{setShowModal(true)}}
          >
            Join Us
          </button>
        </div>
      </div>
    </div>
  );
}

export default MembershipPage;
