import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import "./Navbar.css";
import { navRoutes } from "../../constants/route_constants";
import { useDispatch, useSelector } from "react-redux";
import { drawerToggle } from "../../redux/slices/appStates.slice";
import { aic_logo } from "../../constants/image_constants";

const Navbar = () => {
  const navBgColor = "white";
  const logoColor = "#3e92f2"
  const navFontColor = "#999999";
  const {drawer_open} = useSelector(state=>state.appStates)
  const drawerBtnRef = useRef();
  // const user = useSelector((state) => state.user.data);
  const dispatch = useDispatch();
  // const handleScrollEvent = (e) => {
  //   if (e.target.documentElement.scrollTop > 100 && navBgColor === "white") {
  //     setNavBgColor("#045484");
  //     setLogoColor("white");
  //     setFontColor("white");
  //     // setDropdownColor("white");
  //   } else if (
  //     e.target.documentElement.scrollTop <= 100 &&
  //     navBgColor !== "white"
  //   ) {
  //     setNavBgColor("white");
  //     setLogoColor("#3e92f2");
  //     setFontColor("#999999");
  //     // setFontColor("white");
  //     // setDropdownColor("#0000009d");
  //   }
  // };

  // useEffect(
  //   (e) => {
  //     window.addEventListener("scroll", handleScrollEvent);
  //     return () => {
  //       window.removeEventListener("scroll", handleScrollEvent);
  //     };
  //   },
  // );

  useEffect(()=> {
    if(drawer_open !== true ){
      drawerBtnRef.current.blur();
    }
  },[drawer_open])

  const RenderNavRoutes = ({ routeList }) => {
    return routeList.map((route, index) => {
      return (
        <li key={index} className="nav-li">
          <NavLink
            className="fs-6 text-decoration-none"
            style={{ color: navFontColor }}
            // to={
            //   route.path == "/dashboard"
            //     ? `${route.path}/${user._id}/account-settings`
            //     : route.path
            // }
            onClick={async () => {
              window.scrollTo(0, 0);
              window.location.replace(window.location.origin + route.path);
            }}
          >
            {route.name}
            {route.dropdownRoutes ? (
              <i
                className="bi bi-caret-down-fill"
                style={{ marginLeft: "2px", marginTop: "2px" }}
              />
            ) : null}
          </NavLink>
          {route.dropdownRoutes ? (
            <RenderDropdown dropdownRoutes={route.dropdownRoutes} />
          ) : null}
        </li>
      );
    });
  };

  const RenderDropdown = ({ dropdownRoutes }) => {
    return (
      <div
        className={"dropdown_container"}
        // style={{ backgroundColor: dropdownColor }}
      >
        {dropdownRoutes.map((route, index) => (
          <li key={index}>
            <NavLink
              className="text-decoration-none"
              style={{ color: "#999999" }}
              onClick={async () => {
                window.scrollTo(0, 0);
                window.location.assign(
                  window.location.origin + `/${route.path}`
                );
              }}
            >
              {route.name}
            </NavLink>
          </li>
        ))}
      </div>
    );
  };

  return (
    <nav
      className={`navbar sticky-top`}
      style={{ backgroundColor: navBgColor }}
    >
      <div className={"logo_container"}>
        <img alt="logo_aic" src={aic_logo} />
        {/* <h2 style={{ color: "#3e92f2" }} className="fs-2">AI CLUB</h2> */}
        <a className="navbar-brand" href="/home" style={{ color: logoColor }}>
          AI CLUB
        </a>
      </div>
      <div className={"list_container"}>
        <RenderNavRoutes
          // routeList={user?._id ? navRoutes.signedIn : navRoutes.default}
          routeList={navRoutes.default}
        />
      </div>
      {/* <form className={"search_form"}>
        <input placeholder={"Search"}></input>
        <button style={{ color: navFontColor }}>
          <i className="bi bi-search" />
        </button>
      </form> */}
      <button
        className={"drawerBtn btn"}
        // style={{ backgroundColor: navFontColor }}
        onClick={() => {
          dispatch(drawerToggle());
          document.body.style.overflow = "hidden";
        }}
        ref={drawerBtnRef}
      >
        <i
          className={"bi bi-list fs-1"}
          style={{ color: navBgColor !== "white" ? "white" : "#3e92f2" }}
        />
      </button>
    </nav>
  );
};

export default Navbar;
