import { useLocation, useParams } from "react-router-dom";
import Banner from "../../components/banner/Banner";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.snow.css";
import { useEffect, useState } from "react";
import blogServices from "../../axios/services/blog.service";
import {
  img_blogs_desktop,
  img_blogs_mobile,
} from "../../constants/image_constants";

const BlogPage = () => {
  const state = useLocation();
  const blog = state.blog;
  const { id } = useParams();
  const [blogState, setBlogState] = useState({});
  const getBlogApi = async () => {
    try {
      let response = await blogServices.getBlog(id);
      response?.data?.data && setBlogState(response?.data?.data);
    } catch (err) {
      alert("An Error Occured. Please reload page.");
    }
  };
  useEffect(() => {
    getBlogApi();
  }, [id]);

  return (
    <div className={"blog-page"}>
      <Banner
        title={blog?.title || blogState.title}
        img={{ desktop: img_blogs_desktop, mobile: img_blogs_mobile }}
      />
      <section className={"sec-1"}>
        <ReactQuill
          className={"content"}
          value={blog?.content || blogState.content}
          readOnly={true}
          theme={"bubble"}
        />
      </section>
    </div>
  );
};

export default BlogPage;
