import {
  img_team_web,
  img_team_content,
  img_team_tech,
  img_team_manage,
  img_team_graphic,
  img_team_promo,
  img_team_creative,
  img_team_market,
  img_team_register,
  img_team_social,
  img_membership_mobile,
  img_membership_desktop,
  img_membership,
  img_no_profile,
  img_our_teams_desktop,
  img_our_teams_mobile,
  achievement_clipart,
  mission,
  philosophy,
  what_drives_us,
  IMAGES_CHAIRS,
  IMAGES_EXCOM,
} from "./image_constants";

import { IMAGES_TEAMS } from "./image_constants.js";

export const HOME_PAGE = {
  secTwo: [
    {
      type: "About Us",
      title:
        "Pioneers of a highly ambitious AI society for the Pakistani youth",
      para: "Founded in 2016, the AI CLUB is a derivative of the research centre for Artificial intelligence at NEDUET. Established by a group of undergraduate students of NED University, the club is all about artificial intelligence. Integrating several teams having a diverse set of skills, the AI club brings together all the ideas and individuals passionate about AI technology. We hold ourselves true to the promise of enlightening the young enthusiasts and encouraging emerging startups. The AI club organizes various events including; seminars, workshops, and boot camps featuring researchers and innovators shedding light upon topics such as Machine Learning, Deep Learning, data analysis, entrepreneurship, and robotic automation etc.",
    },
    {
      type: "Our Mission",
      title: "Mentoring and training the young AI innovators",
      para: "The AI club was founded to serve the primary motive of providing opportunities and a networking medium for students, in the domain of artificial intelligence. From the fundamentals of the technology to kickstarting an AI-based business, we strive to provide everything that an ambitious undergrad needs.\nWith a proactive team of dedicated members, we aim to have an even greater impact by expanding our network of members and followers. Moreover, the prospects of the AI club include using the medium of social sites for communicating insightful resources for all the learners out there.",
    },
    {
      type: "Our Vision",
      title:
        "Preparing the future generations of Pakistan for the eminent AI REVOLUTION",
      para: "At AI club we ideate a harmonious and opulent world made possible with the use of AI technologies. Artificial intelligence will revolutionize every industry as we know it. AI club imagines a future where innovations in AI technologies will enhance the well being and state of life in Pakistan. The road to embracing artificial intelligence is long, but to achieve greatness one has to walk alone. AI club has laid the foundation of what will be a society of like-minded innovators, students, teachers and leaders.\nWe envision the AI club to represent a culture and a set of values, a culture of innovation and collaboration to enable our members to reach their fullest potential in life.",
    },
  ],

  footer: {
    logoSubtext:
      "AI Club's objective is to promote Artificial Intelligence education and fund startups created by it’s members.",
    contactInfo: {
      address:
        "Main University Rd, NED University Of Engineering & Technology, Karachi, Karachi City, Sindh 75270, Pakistan",
      phone: "+92 334 3304258",
      email: "info.aiclub@gmail.com",
    },
  },
};

export const ABOUT_PAGE = {
  secOne: [
    {
      title: "Our Mission",
      para: "Our mission is to create awareness amongst the youth and to emphasize the prominence of AI through workshops and seminars. Moreover, we aim to allot funds and financial support to creative minds and help them to sharpen their artistry so that they can flourish their startups and become the innovators of tomorrow.",
      image: mission,
    },
    {
      title: "Our Philosophy",
      para: "The pioneers who laid the foundation of Al had foreseen the future and were aware of the influence of Artificial Intelligence in the upcoming era. When it comes to technology Pakistan has always been at the downside trying to adopt the new things and thus facing hurdles. Therefore, they wanted to establish such a society that could help Pakistan establish esteem in the AI market, hence benefiting the youth.",
      image: philosophy,
    },
    {
      title: "What Drives Us",
      para: "Our purpose is to create awareness amongst the youth and to emphasize the prominence of AI through workshops and seminars. Moreover, we aim to allot funds and financial support to creative minds and help them to sharpen their artistry so that they can flourish their startups and become the innovators of tomorrow.",
      image: what_drives_us,
    },
  ],
  secTwo: {
    title: "President of AI Club",
    subTitle: "Werdah Hashmi",
    para: "Being the President of the AI Club for the tenure of 2019-21, My goal is to transform today’s AI enthusiasts into tomorrow’s AI Professionals by proselytizing AI and Data Science education, research, and career opportunities! I hope that the AI Club continues growing in the years to come and perpetuates the serving of the departments and its students with the same tenacity upon which it has been working since its origination",
    img: IMAGES_EXCOM.president_werdah_hashmi,
  },
  secThree: {
    title: "Celebrate Achievements",
    para: "Ai has been around for years but it is quiet recent that it has started making progress. It is the one of the most growing field in the world now and has already transformed many industries and it will transform many more industries in the near future but the question is that are we ready for that change? We aren’t. The core purpose of AI Club is to motivate students to learn AI on their own and spread knowledge of AI. There are countless resources available on the internet to learn about AI/ML.The beginning is always the hardest but it also the secret of getting ahead . Don't wait for anything as you already have what it takes to become the best in the field.",
    name: "-Usman Abbas, Former VP AI Club",
    img: achievement_clipart,
  },
  secFour: {
    title: "Our Teams",
    para: "A Total Of 10 Teams Function Under The AI Club. From Content Writing To Marketing To Technical Etc, Each Team Consists Of Dedicated And Skilled Individuals.",
  },
  secFive: {
    images: [
      {
        name: "Werdah Hashmi",
        position: "President",
        src: IMAGES_EXCOM.president_werdah_hashmi,
      },
      {
        name: "Hamna Kham",
        position: "Vice President",
        src: IMAGES_EXCOM.vice_president_hamna_khan,
      },
      {
        name: "Wajih Khan",
        position: "General Secretary",
        src: IMAGES_EXCOM.general_secretary_wajih_khan,
      },
      {
        name: "Uzair Khan",
        position: "Technical Head",
        src: IMAGES_EXCOM.technical_head_uzair_khan,
      },
      {
        name: "Haris Khan",
        position: "Media Head",
        src: IMAGES_EXCOM.media_head_haris_khan,
      },
    ],
  },
  secSix: {
    images: [
      {
        name: "Sana Mashood",
        position: "Chair Competitions",
        src: IMAGES_CHAIRS.chair_competitions_sana_mashood,
      },
      {
        name: "Mahnoor Zahid",
        position: "Chair Corporate",
        src: IMAGES_CHAIRS.chair_corporate_mahnoor_zahid,
      },
      {
        name: "Shiraz Waseem",
        position: "Chair Promotions",
        src: IMAGES_CHAIRS.chair_promotions_shiraz_waseem,
      },
      {
        name: "Abdul Ahad Sheikh",
        position: "Chair Events",
        src: IMAGES_CHAIRS.chair_events_abdul_ahad_sheikh,
      },
    ],
  },
};

export const OUR_TEAMS_PAGE = {
  banner: {
    img: {
      mobile: img_our_teams_mobile,
      desktop: img_our_teams_desktop,
    },
  },
  secOne: {
    img: require("../images/teams/ourTeams1.jpeg"),
    title: "Our Excom",
    para: "The Executive Committee is a body of insightful and intellectual minds that possess a strong vision and capabilities to lead the whole of AI CLUB gracefully. Handpicked, chosen, and trusted to carry out all that is planned, whilst supervising the Directors of all domains of the AI Club. The foundation of the AI Club depends on its administration and guidance. They certainly know how to lead the Club, assuming authority when needed and even helping out with menial tasks on busy days! From sudden meetings to excessive celebrations, the Ex-Com remains an inspiration for the Club! It moves with the sole intention to grow the Club not only within the grounds of NED but expanding throughout the world and encouraging AI enthusiasts.",
  },
  secTwo: [
    {
      //Each object is one team.
      //It contains data for their specific page. Such as paragraphs, images, and names
      image: IMAGES_TEAMS.WEB_DEVELOPMENT.team_web_dev,
      path: "team-web-development",
      team: "Web Design and Development",
      pageData: {
        bannerTitle: "Team Web Design and Development",
        secOne: {
          title: "Web Design and Development",
          para: `The official website is a platform where the world learns about AI Club. It is where our audience reads about our research and familiarizes themselves with the AI Club. The importance of this formal platform cannot be emphasized enough. Only the most worthy developers are trusted with this crucial task. Members of Team Web Design and Development are chosen with the sole intention of building a well-designed and maintainable website for our society.\n
          They bring creative and technical skills together to create beautiful and mobile-friendly static web pages. Their expertise lies in creating user-friendly websites whilst increasing organic traffic, brand awareness, and potential leads! It is truly AI Club’s favorite asset!`,
        },
        secTwo: {
          title: "Our Creative Team",
          subTitle:
            "Team Web Design And Development Create Beautiful And Mobile-Friendly Static Web Pages With Their Technical And Creative Skills.",
          images: [
            {
              name: "Erbaz Kamran",
              position: "Director",
              img: IMAGES_TEAMS.WEB_DEVELOPMENT.director_muhammad_erbaz_kamran,
            },
            {
              name: "Abdul Bari",
              position: "Co-Director",
              img: img_no_profile,
            },
          ],
        },
      },
    },
    {
      image: IMAGES_TEAMS.CONTENT_WRITING.team_content_wt,
      path: "team-content-writing",
      team: "Content Writing",
      pageData: {
        bannerTitle: "Team Content Writing",
        secOne: {
          title: "Content Writing",
          para: "Quality content is the best asset to have! It not only helps the Club land better traction on social media but also engages our audience in indulging till the end! Good content provides us with better leads and builds solid trust in our audience, helping the Club become even more visible in the world of Artificial Intelligence. AI Club is proud of its Team Content for always providing consistent, engaging, and high-quality content, covering every aspect of the Club!\nThe Team never disappoints, be it lengthy, well-researched blog posts or last-minute social media captions! Their content always answers any questions one might have. We surely owe them for keeping our audience engaged with their charm and fun!",
        },
        secTwo: {
          title: "Our Creative Team",
          subTitle:
            "Team Content Writing Prepares Content For The AI Club’s Promotional Material Such As Posters, Captions For Social Media Updates, Profiles Of Guest Speakers At AI Club Events, Etc.",
          images: [
            {
              name: "Nizana Khan",
              position: "Director",
              img: IMAGES_TEAMS.CONTENT_WRITING.director_nizana_khan,
            },
            {
              name: "Syed Aali",
              position: "Co-Director",
              img: IMAGES_TEAMS.CONTENT_WRITING.deputy_director_syed_aali,
            },
          ],
        },
      },
    },
    {
      image: IMAGES_TEAMS.TECHNICAL.team_technical,
      path: "team-technical",
      team: "Technical",
      pageData: {
        bannerTitle: "Team Technical",
        secOne: {
          title: "Technical",
          para: "To thrive and survive, a forum surely needs individuals who are familiar with the application of their technical knowledge. Team Technical keeps the events running smoothly via stable connections of projectors and screens. A multidisciplinary team with excellent technical skills, they provide technical guidance whenever and wherever needed. Living in a digital world, our events need to be top-notch in the technological aspects and our technical team surely knows how to get the job done.\nTeam Technical ensures and prepares all technical necessities such as the best interconnection and cables and cords for different computers. The efficiency they add up helps our events run according to the schedule! AI Club truly treasures its Team Technical!",
        },
        secTwo: {
          title: "Our Creative Team",
          subTitle:
            "Team Technical Handles All The Technical Work Related To The Ongoing Projects And Troubleshoot Any Problem That Occurs.",
          images: [
            {
              name: "Ayan Hussain",
              position: "Co-Director",
              img: IMAGES_TEAMS.TECHNICAL.deputy_director_ayan_hussain,
            },
            {
              name: "Haniya Maqsood",
              position: "Director",
              img: IMAGES_TEAMS.TECHNICAL.director_haniya_maqsood,
            },
            {
              name: "Moaaz Siddiqui",
              position: "Co-Director",
              img: IMAGES_TEAMS.TECHNICAL.deputy_director_moaaz_siddiqui,
            },
          ],
        },
      },
    },
    {
      image: IMAGES_TEAMS.MANAGEMENT_AND_LOGISTICS.team_mgmt_log,
      path: "team-management",
      team: "Event Management and Logistics",
      pageData: {
        bannerTitle: "Team Management and Logistics",
        secOne: {
          title: "Management and Logistics",
          para: "The meaning of logistics is to meet customer demands through planning, control, and effective implementation. The storage of related information, goods, and services from origin to destination comes under this particular domain. Team Management and Logistics is here to oversee exactly all of this. By making bringing in all necessary items, from stationery to shields and tokens of appreciation, Team Management and Logistics has got it covered! Without them, AI Club might face more embarrassing moments than just on.",
        },
        secTwo: {
          title: "Our Creative Team",
          subTitle:
            "Team Management And Logistics Promote Team-Building In The Workplace By Helping The Members Of Our Team To Complete Tasks And Take Care Of The Essentials To Be Arranged For The Events And Projects",
          images: [
            {
              name: "Usaid Asif",
              position: "Co-Director",
              img: IMAGES_TEAMS.MANAGEMENT_AND_LOGISTICS
                .deputy_director_usaid_asif,
            },
          ],
        },
      },
    },
    {
      image: IMAGES_TEAMS.GRAPHICS.team_graphics,
      path: "team-graphics",
      team: "Graphics",
      pageData: {
        bannerTitle: "Team Graphics",
        secOne: {
          title: "Graphic Design",
          para: "An event's publicity is totally reliant upon promoting it digitally. Using its exceptional creativity and designing skills, Team Graphic Designing does the job tremendously, from flyers to brochures, posters to banners, Team Graphics provides appealing designs and engaging promotional videos that never fail to amaze all of us! AI Club owes much to the Team for being a pillar for all its events and captivating the audience with their astounding hard work. Not only that, they make sure to highlight all important details that need to be provided via these posters. We are all certainly impressed by their work!",
        },
        secTwo: {
          title: "Our Creative Team",
          subTitle:
            "Team Graphics Serves To Portray Our Ideas To The Audience That Is Not Only Effective But Also Beautiful. They Design Eye-Catching Posters For Announcements And Event Promotions.",
          images: [
            {
              name: "Ali Mukadam",
              position: "Co-Director",
              img: IMAGES_TEAMS.GRAPHICS.director_ali_mukadam,
            },
            {
              name: "Mehak Hussain",
              position: "Co-Director",
              img: IMAGES_TEAMS.GRAPHICS.deputy_director_mehak_hussain,
            },
            {
              name: "Zainab Nawaz",
              position: "Co-Director",
              img: IMAGES_TEAMS.GRAPHICS.deputy_director_zainab_nawaz,
            },
          ],
        },
      },
    },
    {
      image: IMAGES_TEAMS.PROMOTIONS.team_promotions,
      path: "team-promotion",
      team: "Promotion",
      pageData: {
        bannerTitle: "Team Promotion",
        secOne: {
          title: "Promotion",
          para: "There can be plenty reasons to have a team to control promotion. The method of approaching someone in person might be outdated but tops all the other ones! Team promotions is there to reach out to all those who might be potentially interested in the events organized by AI Club. The Team approaches the masses to give them a better insight of what awaits them and answers any queries they might have. It helps generate leads and attract the target audience to bring even more life to our events! The purpose of Team Promotions is to showcase events and be the mode of communcation which links AI Club to the students.",
        },
        secTwo: {
          title: "Our Creative Team",
          subTitle:
            "Team Promotion Creates Events And Organizes All Event Logistics With The Club Or Venue Where The Event Will Be Held. They Advertise Events And Their Venues To Potential Attendees.",
          images: [
            {
              name: "Huma Afzal",
              position: "Co-Director",
              img: IMAGES_TEAMS.PROMOTIONS.deputy_director_huma_afzal,
            },
            {
              name: "Fariya Asif",
              position: "Director",
              img: IMAGES_TEAMS.PROMOTIONS.director_fariya_asif,
            },
            {
              name: "Shah Hussain",
              position: "Co-Director",
              img: IMAGES_TEAMS.PROMOTIONS.deputy_director_shah_hussain,
            },
          ],
        },
      },
    },
    {
      image: IMAGES_TEAMS.CREATIVE.team_creativity,
      path: "team-creativity",
      team: "Creativity",
      pageData: {
        bannerTitle: "Team Creativity",
        secOne: {
          title: "Creativity",
          para: "If it wasn’t for the creative minds around us, we would have been repeating the same patterns and never coming up with innovative and unique ideas. Creativity is of utmost importance in order to stand out. Team Creativity is a bunch of talented and creative minds responsible for making posters, banners, props, and all that is required to showcase the theme and idea of an event in the best possible way and produce exceptional results in a creative manner.\nTeam Creativity is responsible for making any event of The AI Club gripping and unique. They add their charm to our stages and bring it to life! AI Club is glad to have such creative minds on board!",
        },
        secTwo: {
          title: "Our Creative Team",
          subTitle:
            "Team Creativity Manages The Creative Process From Concept To Completion Of An Event, Making Sure That The Site Of The Event Looks Presentable And Well Decorated For The Day.",
          images: [
            {
              name: "Mahwish Hashmi",
              position: "Director",
              img: IMAGES_TEAMS.CREATIVE.director_mahwish_hashmi,
            },
            {
              name: "Hiba Khan",
              position: "Co-Director",
              img: IMAGES_TEAMS.CREATIVE.deputy_director_hiba_khan,
            },
          ],
        },
      },
    },
    {
      image: IMAGES_TEAMS.SOCIAL_MEDIA.team_social_media_and_marketing,
      path: "team-marketing",
      team: "Social Media Marketing",
      pageData: {
        bannerTitle: "Team Social Media Marketing",
        secOne: {
          title: "Content Writing",
          para: "Digital marketing is not the art of selling a product. It is an art of making people buy the product that you sell.” — Hecate Strategy.\nThis vital role is played by none other than our Team Marketing. They represent the AI Club by contacting companies for partnerships and building brand awareness. The Team works hard to get the best sponsors for our events and manage everything in financial aspects. Most importantly, they communicate with the customers to understand the audience, so you can enjoy events in the best way! AI Club is proud of its Team Marketing in all the ways that count!\n\nWith the objective of spreading the AI Club's message to the masses, this team holds the responsibility and honor of handling and representing the club on social platforms, to engage our audience and keep them updated with all events, seminars, and workshops that AI Club conducts! They attract our target audience using tips and trips to promote posts, with perfect timing. With the availability of so many platforms in today's digital world, they make sure you and the club, stay connected.",
        },
        secTwo: {
          title: "Our Creative Team",
          subTitle:
            "Team Marketing Promotes The Business And Mission Of The AI Club. They Contact And Pitch Events To Companies And Software Houses For Sponsorships And Partnerships.",
          images: [
            {
              name: "Ammarah Awan",
              position: "Co-Director",
              img: IMAGES_TEAMS.SOCIAL_MEDIA.director_ammarah_awan_deputy,
            },
            {
              name: "Dayan",
              position: "Director",
              img: img_no_profile,
            },
            {
              name: "Maaz Mustafa",
              position: "Co-Director",
              img: IMAGES_TEAMS.SOCIAL_MEDIA.deputy_director_maaz_mustafa,
            },
          ],
        },
      },
    },
    {
      image: IMAGES_TEAMS.HR_AND_REGISTRATIONS.team_hr_and_registeration,
      path: "team-hr-and-registerations",
      team: "HR and Registerations",
      pageData: {
        bannerTitle: "Team HR and Registerations",
        secOne: {
          title: "HR and Registerations",
          para: "We could plan an event down to the last detail, but the execution depends primarily on our members. Their energy is what makes these events a true success. To keep the members happy and stress-free, AI Club has its very own Team HR, which oversees their duties and tasks, and monitors their efficiency as a member. HR is a vital aspect of any organization, without which our society would lack structure and fail to improvise over time. From improving employee well-being to risk management, HR covers it all.\n\nRegistering for events becoming a tedious job? Team HR and Registrations has it all sorted! You'll find them at the registration desks to make your life easier. From pre-booking to on-spot, they manage their job effortlessly and keep things running effortlessly. They work together to provide the necessary capabilities for the events and ensure the proper execution of plans, so the event runs efficiently and your day goes enjoyable! Events would surely be stressful without the Team Registration backing us up!",
        },
        secTwo: {
          title: "Our Creative Team",
          subTitle:
            "Team Registration Design And Publish Registration Forms For AI Club’s Events And Make Sure That The Whole Registration Process Is Carrying Out With Ease.",
          images: [
            {
              name: "Tehreem Ekram",
              position: "Director",
              img: IMAGES_TEAMS.HR_AND_REGISTRATIONS.director_tehreem_ekram,
            },
            {
              name: "Minahil Tariq",
              position: "Co-Director",
              img: IMAGES_TEAMS.HR_AND_REGISTRATIONS.deputy_director_minahil_tariq,
            },
          ],
        },
      },
    },
  ],
};

export const OUR_ACHIEVEMENTS_PAGE = {
  secOne: {
    headings: ["WHAT WE ACHIEVED", "OUR SUCCESS STORY"],
    paras: [
      `In AI and Data Science once an event happens rarely, it is called outlier and when it becomes frequent it is trend. To change trend it takes hard working and dedicated individuals like Usman Abbas and M. Affan Khan who are trend setters. AI Club continues the legacy of gifting courses and promoting achievers of AI with gifts and supports them fully for the future. Following that, Usman Abbas and M. Affan Khan were awarded Google Home and Alexa for being the most hardworking students and completed Deep Learning Specialization in NED at undergrad-level.\nIn AI Club we not only support and promote our AI community but also go an extra mile to appreciate their efforts, AI Club with history of gifting courses and runner ups of Kaggle competitors this time giving away Alexa and Google Home devices to all the dedicated learners who first completed Deep Learning specialization. Cheers to the winners!!`,
      `Ai has been around for years but it is quiet recent that it has started making progress. It is the one of the most growing field in the world now and has already transformed many industries and it will transform many more industries in the near future but the question is that are we ready for that change? We aren’t. The core purpose of AI Club is to motivate students to learn AI on their own and spread knowledge of AI. There are countless resources available on the internet to learn about AI/ML.The beginning is always the hardest but it also the secret of getting ahead . Dont wait for anything as you already have what it takes to become the best in the field.`,
    ],
    author: "-Usman Abbas, Former VP AI Club",
  },
};

export const MEMBERSHIP_PAGE = {
  banner: {
    img: {
      mobile: img_membership_mobile,
      desktop: img_membership_desktop,
    },
    title: "Membership",
  },
  secOne: {
    img: img_membership,
    title: "Membership",
    text: "AI Club objective is to promote Artificial Intelligence and Data Science Education in students. Also we encourage students to create start ups and provide equity based funding to the potential ideas and founders. Artificial Intelligence club is NED student’s organization pursuing to build community of AI/machine learning professionals where we arrange quarterly meet ups on AI to evangelize the opportunities, challenges, success, failures, and current developments in AI so we can concrete the gap with outside world and our students can compete with tech war. We aim to provide students the access to various courses on different outstanding online platforms and encourage them to take part in Kaggle competitions to give AI Club a setup for AI tech incubators and provide a better platform by being a source of linkage for students nationwide.",
  },
  modal: {
    title: "NOTICE",
    text: "Recruitments for the Artificial Intelligence Club are currently closed.\n\n To reach out to us for details, you may email us at info.aiclub@gmail.com ",
  },
};
