import React from 'react';
import { FONT_COLOR_BLUE } from '../../constants/color_constants';
import './Footer.css';
import 'bootstrap-icons/font/bootstrap-icons';
// import aicLogo from "../../images/AIC-logo.png";
import aicLogo from '../../images/AIC-logo.png';
import { HOME_PAGE } from '../../constants/string_constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCoffee,
  faEnvelope,
  faLocationDot,
  faPhone,
  faThumbsUp,
} from '@fortawesome/free-solid-svg-icons';
const Footer = () => {
  const { footer } = HOME_PAGE;
  return (
    // <div>
    //   <div className={"footer_container"}>
    //     <div className={"sub_container"}>
    //       <img className={"logo_aic"} src={aicLogo} />
    //       <br />
    //       <b style={{ width: "80%" }}>{footer.logoSubtext}</b>
    //     </div>
    //     <div className={"sub_container , contact_info"}>
    //       <h1>Contact Info</h1>
    //       <ul>
    //         <li>
    //           <div>
    //             <i
    //               className={"bi bi-geo-alt-fill"}
    //               style={{ color: FONT_COLOR_BLUE }}
    //             />
    //           </div>
    // <a href={"https://goo.gl/maps/dgkSNHBbpeuTdRdF6"}>
    //   {footer.contactInfo.address}
    // </a>
    //         </li>
    //         <li>
    //           <div>
    //             <i
    //               className={"bi bi-telephone-fill"}
    //               style={{ color: FONT_COLOR_BLUE }}
    //             />
    //           </div>
    //           {footer.contactInfo.phone}
    //         </li>
    //         <li>
    //           <div>
    //             <i
    //               className={"bi bi-envelope-fill"}
    //               style={{ color: FONT_COLOR_BLUE }}
    //             />
    //           </div>
    //           <a href={"mailto:info.aiclub@gmail.com?"}>
    //             {footer.contactInfo.email}
    //           </a>
    //         </li>
    //       </ul>
    //     </div>
    // <div className={"sub_container"}>
    //   <h1>Our Social Links</h1>
    //   <iframe
    //     name="f271174998c2124"
    //     data-testid="fb:page Facebook Social Plugin"
    //     title="fb:page Facebook Social Plugin"
    //     frameBorder="0"
    //     allowtransparency="true"
    //     allowFullScreen={true}
    //     scrolling="no"
    //     allow="encrypted-media"
    //     src="https://www.facebook.com/v2.10/plugins/page.php?app_id=&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df1bfe6fc56b131%26domain%3Daiclub.rcai.pk%26is_canvas%3Dfalse%26origin%3Dhttp%253A%252F%252Faiclub.rcai.pk%252Ff30291e23e08c24%26relation%3Dparent.parent&amp;hide_cover=false&amp;hide_cta=true&amp;href=https%3A%2F%2Fwww.facebook.com%2Faiclubned%2F&amp;locale=en_GB&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false&amp;tabs=&amp;"
    //     style={{
    //       textAlign: "center",
    //       visibility: "visible",
    //       display: "flex",
    //       alignSelf: "center",
    //       justifyContent: "center",
    //       position: "relative",
    //       width: "100%",
    //     }}
    //   ></iframe>
    //   <div className={"round_btns_container"}>
    //     <div className="round_btn">
    //       <a href="https://twitter.com/aiclub_ned">
    //         <i className="bi bi-twitter"></i>
    //       </a>
    //     </div>
    //     <div className="round_btn">
    //       <a href="https://instagram.com/ai_clubofficial">
    //         <i className="bi bi-instagram" />
    //       </a>
    //     </div>
    //     <div className="round_btn">
    //       <a href="https://www.linkedin.com/company/ned-aiclub/">
    //         <i className="bi bi-linkedin" />
    //       </a>
    //     </div>
    //   </div>
    // </div>
    //     <div className={"sub_container"}>
    //       <h1>Important Links</h1>
    //       <ul className="imp_links">
    //         <li>
    //           <a href="/aboutUs">
    //             <div>
    //               <i className={"bi bi-caret-right-fill"} />
    //               <span>About Us</span>
    //             </div>
    //           </a>
    //         </li>
    //         <li>
    //           <a href="/contact_us">
    //             <div>
    //               <i className={"bi bi-caret-right-fill"} />
    //               <span>Contact Us</span>
    //             </div>
    //           </a>
    //         </li>
    //         <li>
    //           <a href="/our_team">
    //             <div>
    //               <i className={"bi bi-caret-right-fill"} />
    //               <span>Our Team</span>
    //             </div>
    //           </a>
    //         </li>
    //         <li>
    //           <a href="/events">
    //             <div>
    //               <i className={"bi bi-caret-right-fill"} />
    //               <span>Events</span>
    //             </div>
    //           </a>
    //         </li>
    //         <li>
    //           <a href="/membership">
    //             <div>
    //               <i className={"bi bi-caret-right-fill"} />
    //               <span>Membership</span>
    //             </div>
    //           </a>
    //         </li>
    //       </ul>
    //     </div>
    //   </div>
    //   <footer>
    //     Copyright © 2020. All Rights Reserved by{" "}
    //     <b style={{ color: FONT_COLOR_BLUE }}>AIC CLUB -RCAI</b>
    //   </footer>
    // </div>
    // <div className="p-5 footer" style={{backgroundColor:"black"}}>
    //   <div className="container mt-3 ">
    //     <div className="row">
    //       <div className="col-md-3 col-12 ">
    //         <div className="d-flex flex-column">
    //           <div className="mx-auto"><img src={aicLogo} width="120px"/></div>
    //           <small className="ms-3 mt-3 text-light d-md-flex d-none">{footer.logoSubtext}</small>
    //         </div>
    //       </div>
    //       <div className="col-md-3 col-12 mt-md-0 mt-5 links">
    //         <h4 className=" text-light">Important Links</h4>
    //         <div className="d-flex flex-column"><a href="#" className="text-light">About Us</a>
    //         <a href="#" className="my-2 text-decoration-none text-light">Our Teams</a>
    //         <a href="#" className="text-light">Our Projects</a>
    //         <a href="#" className="my-2 text-decoration-none text-light">Our Blogs</a>
    //         <a href="#" className="text-light">Courses</a>
    //         <a href="#" className="text-light my-2">Contact</a></div>
    //       </div>
    //       <div className="col-md-3 col-12 mt-md-0 mt-4 links">
    //         <h4 className="text-light">Contact</h4>
    //         {/* <div className="">
    //           <a href={"https://goo.gl/maps/dgkSNHBbpeuTdRdF6"} className="bi bi-geo-alt-fill text-decoration-none text-start" style={{width:"30px",padding:"20px",fontSize:"30px",borderRadius:"50%"}}>{footer.contactInfo.address}</a>
    //           <a className="text-light text-start" href={"https://goo.gl/maps/dgkSNHBbpeuTdRdF6"}>
    //               {footer.contactInfo.address}
    //           </a>
    //         </div>
    //         <div className="d-flex mt-3">
    //         <i className="bi bi-telephone-fill bg-light text-center fs-5 me-3" style={{width:"30px",height:"30.6px",borderRadius:"50%"}}></i>
    //           <a className="text-light" href={"https://goo.gl/maps/dgkSNHBbpeuTdRdF6"}>
    //               {footer.contactInfo.phone}
    //           </a>
    //         </div>*/}
    //         <div className="mt-md-0 mt-3 d-flex flex-row ">
    //         <div><a className="text-dark rounded-circle p-2 bg-light mx-1" href={"https://goo.gl/maps/dgkSNHBbpeuTdRdF6"}>
    //           <i className="bi bi-geo-alt-fill p-0 m-0" />
    //         </a></div>
    //         <p className=" text-light ps-1">{footer.contactInfo.address}</p></div>
    //         <div className="m-0 p-0  d-flex flex-row"><div><a className="text-dark rounded-circle p-2 bg-light mx-1" href={"https://goo.gl/maps/dgkSNHBbpeuTdRdF6"}>
    //           <i className="bi bi-telephone-fill p-0 m-0" />
    //         </a></div>
    //         <p className=" text-light ps-1">{footer.contactInfo.phone}</p></div>
    //       </div>
    //       <div className=" col-md-3 col-12 mt-mb-0 ">
    //         {/* <h4 className=" mx-auto text-light d-md-flex d-none" style={{width:"fit-content"}}>Social Links</h4>
    //         <div className="text-center mt-4">
    //         <a className="text-dark rounded-circle p-2 rounded-circle bg-light" href={"https://goo.gl/maps/dgkSNHBbpeuTdRdF6"}>
    //         <i className="bi bi-facebook " />
    //           </a>
    //           <a className="text-dark rounded-circle p-2 mx-3 rounded-circle bg-light" href={"https://goo.gl/maps/dgkSNHBbpeuTdRdF6"}>
    //           <i className="bi bi-twitter" />
    //           </a>
    //           <a className="text-dark rounded-circle p-2 rounded-circle bg-light" href={"https://goo.gl/maps/dgkSNHBbpeuTdRdF6"}>
    //           <i className="bi bi-linkedin " />
    //           </a>
    //         </div> */}
    //         {/* <div className={"sub_container"}> */}
    //   <h4 className=" mx-auto text-light d-md-flex d-none " style={{width:"fit-content"}}>Social Links</h4>
    //   <div className=""><iframe
    //   name="f271174998c2124"
    //   data-testid="fb:page Facebook Social Plugin"
    //   title="fb:page Facebook Social Plugin"
    //   frameBorder="0"
    //   allowtransparency="true"
    //   allowFullScreen={true}
    //   scrolling="no"
    //   allow="encrypted-media"
    //   src="https://www.facebook.com/v2.10/plugins/page.php?app_id=&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df1bfe6fc56b131%26domain%3Daiclub.rcai.pk%26is_canvas%3Dfalse%26origin%3Dhttp%253A%252F%252Faiclub.rcai.pk%252Ff30291e23e08c24%26relation%3Dparent.parent&amp;hide_cover=false&amp;hide_cta=true&amp;href=https%3A%2F%2Fwww.facebook.com%2Faiclubned%2F&amp;locale=en_GB&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false&amp;tabs=&amp;"
    //   style={{
    //     textAlign: "center",
    //     visibility: "visible",
    //     display: "flex",

    //     justifyContent: "center",
    //     position: "relative",
    //     width: "100%",
    //   }}
    // ></iframe></div>
    // <div className={"round_btns_container"}>
    //   <div className="round_btn">
    //     <a href="https://twitter.com/aiclub_ned">
    //       <i className="bi bi-twitter"></i>
    //     </a>
    //   </div>
    //   <div className="round_btn">
    //     <a href="https://instagram.com/ai_clubofficial">
    //       <i className="bi bi-instagram" />
    //     </a>
    //   </div>
    //   <div className="round_btn ">
    //     <a href="https://www.linkedin.com/company/ned-aiclub/">
    //       <i className="bi bi-linkedin" />
    //     </a>
    //   </div>
    // </div>
    //     {/* </div> */}
    //       </div>
    //     </div>
    //   </div>
    //   <div className="text-center text-light mt-5 mt-md-3 pt-5 mx-auto"><small>Copyright © {new Date().getFullYear()}. All Rights Reserved by AIC CLUB -RCAI</small></div>
    // </div>
    <div className='footer'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6 col-lg-3'>
            <div className='footer-about'>
              <img
                src={aicLogo}
                width='120px'
                className=' mx-auto mx-md-0 mb-1'
              />
              <p className='d-sm-flex d-none'>
                AI Club's objective is to promote Artificial Intelligence
                education and fund startups created by it's members.
              </p>
            </div>
          </div>
          <div className='col-md-6 col-lg-3 mt-sm-0 mt-2'>
            <div className='footer-links'>
              <h4>Important Links</h4>
              <a href='/about'>
                <i className='bi bi-chevron-right'></i> About Us
              </a>
              <a href='/our-teams'>
                <i className='bi bi-chevron-right'></i> Our Teams{' '}
              </a>
              <a href='/projects'>
                <i className='bi bi-chevron-right'></i> Our Projects
              </a>
              <a href='/blogs'>
                <i className='bi bi-chevron-right'></i> Our Blogs
              </a>
              <a href='/events'>
                <i className='bi bi-chevron-right'></i> Events
              </a>
              <a href='/contact-us'>
                <i className='bi bi-chevron-right'></i> Contact
              </a>
            </div>
          </div>
          <div className='col-md-6 col-lg-3 my-sm-0 my-3'>
            <div className='footer-contact'>
              <h4>Contact Info</h4>
              <p className='text-secondary'>
                <a
                  href='https://goo.gl/maps/fGQVVeCvPNbQS8K5A'
                  rel='noreferrer'
                  target='_blank'
                >
                  <FontAwesomeIcon className='icon' icon={faLocationDot} />
                  {footer.contactInfo.address}
                </a>
              </p>
              <p className='text-secondary'>
                <a href={`tel:${footer.contactInfo.phone}`}>
                  <FontAwesomeIcon className='icon' icon={faPhone} />
                  {footer.contactInfo.phone}
                </a>
              </p>
              <p className='text-secondary'>
                <a href={`mailto:${footer.contactInfo.email}`}>
                  <FontAwesomeIcon className='icon' icon={faEnvelope} />
                  {footer.contactInfo.email}
                </a>
              </p>
            </div>
          </div>
          <div className='col-md-6 col-lg-3'>
            <div className='footer-project'>
              <h4 className='  ' style={{ width: 'fit-content' }}>
                Social Links
              </h4>
              <div className=''>
                <iframe
                  name='f271174998c2124'
                  data-testid='fb:page Facebook Social Plugin'
                  title='fb:page Facebook Social Plugin'
                  frameBorder='0'
                  allowtransparency='true'
                  allowFullScreen={true}
                  scrolling='no'
                  allow='encrypted-media'
                  src='https://www.facebook.com/v2.10/plugins/page.php?app_id=&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df1bfe6fc56b131%26domain%3Daiclub.rcai.pk%26is_canvas%3Dfalse%26origin%3Dhttp%253A%252F%252Faiclub.rcai.pk%252Ff30291e23e08c24%26relation%3Dparent.parent&amp;hide_cover=false&amp;hide_cta=true&amp;href=https%3A%2F%2Fwww.facebook.com%2Faiclubned%2F&amp;locale=en_GB&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false&amp;tabs=&amp;'
                  style={{
                    textAlign: 'center',
                    visibility: 'visible',
                    display: 'flex',

                    justifyContent: 'center',
                    position: 'relative',
                    width: '100%',
                  }}
                ></iframe>
              </div>
              <div className={'round_btns_container'}>
                <div className='round_btn'>
                  <a
                    rel='noreferrer'
                    target='_blank'
                    href='https://twitter.com/aiclub_ned'
                  >
                    <i className='bi bi-twitter'></i>
                  </a>
                </div>
                <div className='round_btn'>
                  <a
                    rel='noreferrer'
                    target='_blank'
                    href='https://instagram.com/ai_clubofficial'
                  >
                    <i className='bi bi-instagram' />
                  </a>
                </div>
                <div className='round_btn '>
                  <a
                    rel='noreferrer'
                    target='_blank'
                    href='https://www.linkedin.com/company/ned-aiclub/'
                  >
                    <i className='bi bi-linkedin' />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='text-center text-light mt-5 mt-md-3 mx-auto'>
        <small>
          Copyright © {new Date().getFullYear()}. All Rights Reserved by AIC
          CLUB -RCAI
        </small>
      </div>
      <div className='copyright'>
        <div className='container'>
          <div className='row align-items-center'></div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
