import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import blogServices from "../../axios/services/blog.service";
import Banner from "../../components/banner/Banner";
import BlogCard from "../../components/blogCard/BlogCard";
import ClipLoader from "react-spinners/ClipLoader";
import "./Blogs.css";
import {
  img_blogs_desktop,
  img_blogs_mobile,
} from "../../constants/image_constants";
import { useDispatch, useSelector } from "react-redux";
import { getBlogList } from "../../redux/slices/blog.slice";
import { useScrollToBottom, useWindowScrolledToBottom } from "../../utils/events.utils";
function BlogsPage() {
  const listRef = useRef();
  useScrollToBottom(listRef);
  useWindowScrolledToBottom()

  const { blogs, page, isLastPage, lastPage, loader } = useSelector(
    ({ blog }) => blog
  );
  const size = 6;
  const dispatch = useDispatch();
  

  useEffect(() => {
    !isLastPage && dispatch(getBlogList({page: page || 0, size}));
  }, []);

  const RenderBlogsList = () => {
    return blogs.map((obj) => {
      return (
        <BlogCard
          id={obj._id}
          thumbnail={obj.thumbnail}
          title={obj.title}
          createdOn={obj.created_on}
          preview={obj.preview}
        />
      );
    });
  };

  return (
    <div className={"blogsPage"}>
      <Banner
        title={"Blogs"}
        img={{ mobile: img_blogs_mobile, desktop: img_blogs_desktop }}
      />
      {!loader && blogs?.length > 0 ? (
        <section className={"sec-1"}>
          <RenderBlogsList ref={listRef}/>
        </section>
      ) : (
        <section className={"sec-1-loader"}>
          <ClipLoader size={100} loading={loader} color={"blue"} />
        </section>
      )}
    </div>
  );
}

export default BlogsPage;
