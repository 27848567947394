import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, Navigate, Outlet, useLocation } from "react-router-dom";
import "./App.css";
import Drawer from "./components/drawer/Drawer";
import Footer from "./components/footer/Footer";
import Navbar from "./components/navbar/Navbar";
import AboutPage from "./pages/about/AboutPage";
import OurTeamsPage from "./pages/about/OurTeamsPage";
import ContactPage from "./pages/contact/ContactPage";
import "./pages/home/HomePage";
import HomePage from "./pages/home/HomePage";
import InstructorRegisteration from "./pages/registration/InstructorRegisteration";
import StudentRegisteration from "./pages/registration/StudentRegisteration";
import { closeDrawer } from "./redux/slices/appStates.slice";
import TeamPage from "./pages/about/TeamPage";
import BlogsPage from "./pages/blogs/BlogsPage";
import BlogPage from "./pages/blogs/BlogPage";
import MembershipPage from "./pages/membership/MembershipPage";
import EventsPage from "./pages/about/EventsPage";

import Projects from "./pages/projects/ProjectsPage";
import Project from "./pages/projects/ProjectPage";

import EventPage from "./pages/about/EventPage";
import EventFormPage from "./pages/about/EventFormPage";

function App() {
  const drawerOpen = useSelector((state) => state.appStates.drawer_open);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (drawerOpen === true) {
      dispatch(closeDrawer());
      document.body.style.overflow = "auto";
    }
  }, []);

  useEffect(() => {
    if (drawerOpen === false && document.body.style.overflow != "auto") {
      document.body.style.overflow = "auto";
    }
  }, [location, drawerOpen]);

  return (
    <>
      <Drawer isOpen={drawerOpen} />
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/blogs" element={<Outlet />}>
          <Route index element={<BlogsPage />} />
          <Route path=":id" element={<BlogPage />} />
        </Route>
        <Route index path="/about" element={<AboutPage />} />
        <Route path="/our-teams" element={<Outlet />}>
          <Route index element={<OurTeamsPage />} />
          <Route path=":path" element={<TeamPage />} />
        </Route>
        <Route path="/events" element={<Outlet />}>
          <Route index element={<EventsPage />} />
          <Route path=":id" element={<Outlet />}>
            <Route index element={<EventPage />} />
            <Route path="form/:id" element={<EventFormPage />} />
          </Route>
        </Route>
        <Route path="/eventform" element={<EventFormPage />} />
        <Route
          path="/student-registeration"
          element={<StudentRegisteration />}
        />
        <Route
          path="/instructor-registeration"
          element={<InstructorRegisteration />}
        />
        <Route path="/contact-us" element={<ContactPage />} />
        <Route path="/membership" element={<MembershipPage />} />
        <Route path="/projects" element={<Outlet />}>
          <Route index element={<Projects />} />
          <Route path=":id" element={<Project />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
