import React from "react";
import "./Home.css";
import 'animate.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import {ImageSlider} from '../../components/imageSlider/ImageSlider';

import HeroSection from "../../components/homepage_components'/hero_section/HeroSection";
import AboutSection from "../../components/homepage_components'/aboutus_section/AboutSection";
import Projects from "../../components/homepage_components'/projects/Projects";
import AicWork from "../../components/homepage_components'/aicwork/AicWork";
import Sponsors from "../../components/homepage_components'/sponsors/Sponsors";
import BlogsSection from "../../components/homepage_components'/blogSection/BlogsSection";
import { AnimationOnScroll } from "react-animation-on-scroll";

export default function HomePage() {


  return (
    <div className="home">
      <HeroSection/>
      <AboutSection/>
      <Projects/>
      <AicWork/>
      <section className={"sec sec4 ourTeams-sec"}>
        <AnimationOnScroll animateOnce={true} delay={300} animateIn="animate__fadeInDown" className={"ourTeams-container"}>
          <h1 className="fw-bold text-center">Our Team</h1>
          <h3 className="container text-center fs-5">
            A Total Of <strong style={{ color: "#44699d" }}>10 Teams</strong>{" "}
            Function Under The AI Club. From Content Writing To Marketing To
            Technical Etc, Each Team Consists Of Dedicated And Skilled
            Individuals.
          </h3>
          <div className="imgSlide">
            <ImageSlider/>
          </div>
        <div className={"navSpace"}></div>
        </AnimationOnScroll>
      </section>
      <BlogsSection/>
      <Sponsors/>
      {/* <section className={"sec6 sec"}>
        <div className={"sec_div"}>
          <h1 style={{ width: "80%", textAlign: "center", paddingTop: "5%" }}>
            How AI Club Works
          </h1>
          <h3>
            The AI Club Brings The Young AI Startups Into The Spotlight Linking
            Them With Potential Investors.
          </h3>
        </div>
        <div className={"sec5_body"}>
          <div className={"imageContainer"}>
            <img src={image4} className={"sec5_img"} />
          </div>
          <div className={"sec5_text"}>
            <h2 style={{ width: "80%", textAlign: "center", paddingTop: "5%" }}>
              Preparing undergrad students to dominate the industry by:
            </h2>
            <ul
              className={"listPrepare"}
              style={{ textAlign: "left", width: "80%", fontSize: "16px" }}
            >
              <li>
                The Annual AI fest provides a medium for sharing and
                implementing breakthrough ideas.
              </li>
              <br />
              <li>Arranging seminars, bootcamps, and workshops etc.</li>
              <br />
              <li>Providing access to online courses.</li>
              <br />
            </ul>
          </div>
        </div>
      </section> */}
      {/* <section className="sec sec6">
        <div className="navSpace"></div>

        <div
          className={"bgImgSec6"}
          style={{
            backgroundImage: `url(${image5})`,
          }}
        />
        <div className="sec_div">
          <h1>Our Blogs</h1>
          <br />
        </div>
        <div className="blogsList">
          {blogs.map((obj, i) => {
            return (
              <div key={obj._id} className={`blog blog-${i}`}>
                <img src={obj.thumbnail} />
                <div style={{ textAlign: "left" }}>
                  <h4 className="blogTitle">{obj.title}</h4>
                  <p className="blogDate">
                    {new Date(obj.created_on).toLocaleDateString(
                      "en-US",
                      dateOptions
                    )}
                  </p>
                  <p className="blogPreview">{obj.preview}</p>
                </div>
              </div>
            );
          })}
        </div>
        <Link
          style={{
            alignSelf: "flex-end",
            marginRight: "20px",
            marginTop: "20px",
          }}
          to={"/blogs"}
        >{`Find More >>>`}</Link>
      </section> */}
    </div>
  );
}
