import React from "react";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css";

const BlogCard = (blog) => {
    return (
        <AnimationOnScroll
            key={blog.id}
            animateOnce={true}
            animateIn="animate__fadeInRight"
            className="blog-card-container"
          >
            <a
              className="card blog-card"
              href={`/blogs/${blog._id}`}
              onClick={blog.onClick}
            >
              <img
                className="blog-card-img rounded-3"
                src={blog.thumbnail}
                alt={blog.title}
              ></img>
              <div className="card-body pb-0 text-start">
                <div className="">
                  <i className="bi bi-calendar-event text-primary"></i>{" "}
                  <span
                    className="text-muted fw-bold"
                    style={{ fontSize: "13px" }}
                  >
                    {new Date(blog.createdOn).toDateString()}
                  </span>
                </div>
                <h5 className="card-title fw-bold my-2 ellipse-style-2">{blog.title}</h5>
                <p className="card-text fw-bold text-muted py-0 ellipse-style-4">
                  {blog.preview}
                </p>
                <br/>
              </div>
            </a>
          </AnimationOnScroll>
        
    )
  }

export default BlogCard;